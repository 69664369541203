import React, { useEffect, useMemo, useState } from 'react'
import { ReactComponent as ArrowRightRounded } from '../../assets/svg/arrowForward.svg'
import { Box, BoxProps, IconButton, Stack, styled } from '@mui/material'
import { useTranslation } from 'react-i18next'

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.white[theme.palette.mode],
  width: '24px',
  height: '24px',
  '.icon': {
    color: theme.palette.primaryMain[theme.palette.mode],
  },
  ':hover': {
    backgroundColor: theme.palette.secondaryLight[theme.palette.mode],
  },
}))

interface CommonCarousalProps<T> extends BoxProps {
  items: Array<T>
  ContentComponent: React.FC<T>
  index?: number
  setIndex?: (item: number) => void
  NoDataComponent?: React.ReactNode
}

export const CommonCarousal = <T,>({
  items = [],
  ContentComponent,
  index = 0,
  setIndex,
  NoDataComponent,
  ...rest
}: CommonCarousalProps<T>) => {
  const { t } = useTranslation('common')
  const [selectedIndex, setSelectIndex] = useState<number>(index)
  const selectedItem = useMemo(() => items[selectedIndex], [selectedIndex, items])

  useEffect(() => {
    if (index != null) setSelectIndex(index)
  }, [index])

  const clickForward = () => {
    if (selectedIndex < items.length - 1) {
      setSelectIndex(selectedIndex + 1)
      setIndex && setIndex(selectedIndex + 1)
    }
  }

  const clickBackward = () => {
    if (selectedIndex > 0) {
      setSelectIndex(selectedIndex - 1)
      setIndex && setIndex(selectedIndex - 1)
    }
  }

  return (
    <Box {...rest} width={'100%'} height={'100%'}>
      {items && items.length > 0 ? (
        <>
          <Stack
            direction={'row'}
            width={'100%'}
            justifyContent={
              selectedIndex != 0 && selectedIndex != items.length - 1
                ? 'space-between'
                : selectedIndex == 0
                ? 'flex-end'
                : 'flex-start'
            }
            position={'relative'}
            top={'45%'}
            className="pl-8-core pr-8-core"
            height={0}
          >
            {selectedIndex != 0 && (
              <StyledIconButton onClick={clickBackward}>
                <ArrowRightRounded className="icon" />
              </StyledIconButton>
            )}
            {selectedIndex != items.length - 1 && (
              <StyledIconButton onClick={clickForward}>
                <ArrowRightRounded style={{ transform: 'rotate(180deg)' }} className="icon" />
              </StyledIconButton>
            )}
          </Stack>
          {selectedItem != undefined && (
            <ContentComponent {...selectedItem}>
              <></>
            </ContentComponent>
          )}
        </>
      ) : (
        NoDataComponent ?? <>{t('no-images')}</>
      )}
    </Box>
  )
}
