import { Autocomplete, FormControl, FormControlLabel, MenuItem, Radio, RadioGroup } from '@mui/material'
import { FC, Fragment, useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { invalidationActivityLogCache } from 'src/api/activity-log'
import { invalidateCertificateCache } from 'src/api/Certificates'
import { invalidateLocalityCache, useGetLocalities } from 'src/api/localities'
import {
  invalidateMainComponentCache,
  useChangeCageInMainComponent,
  useUpdateMainComponentToOutgoing,
} from 'src/api/main-component'
import { invalidatePositionCache, useGetCagePositionsByLocality } from 'src/api/positions'
import { AppTextField } from 'src/components/AppTextField'
import { AppForm, FormSubmitButton } from 'src/components/FormCore'
import { GenericDialogLayout } from 'src/components/GenericDialogLayout'
import { CenteredSpinner } from 'src/components/Spinner'
import { APIResultCount, NO_VALID_CERTIFICATE } from 'src/constant'
import { ILocality, IMooring, IPosition } from 'src/generated-types'
import { ChangeLocationType } from 'src/generated-types/change-location-type'
import { IMainComponent } from 'src/generated-types/main-component'
import { useZodForm } from 'src/hooks/zod-form'
import { ShowDialogProps } from 'src/show-dialog'
import { Flex } from 'src/styles/flexComponent'
import { getCageName } from 'src/utils/function'
import { z } from 'zod'

interface ChangeLocationDialogProps extends ShowDialogProps {
  customerId: EntityId
  localityId: EntityId
  mainComponent: IMainComponent
}

const ChangeLocationDialog: FC<ChangeLocationDialogProps> = ({ customerId, localityId, mainComponent, pop }) => {
  const { mutateAsync } = useChangeCageInMainComponent(customerId as EntityId, mainComponent.id as EntityId)
  const { mutate: sendToLocality } = useUpdateMainComponentToOutgoing(
    customerId as EntityId,
    mainComponent.id as EntityId,
  )
  const { data: localities, isLoading } = useGetLocalities(customerId, { page: 0, pageSize: APIResultCount.Max })
  const { data, isLoading: isLoadingPositions } = useGetCagePositionsByLocality(customerId, localityId)

  const { t } = useTranslation(['common', 'main-component'])
  const [formType, setFormType] = useState<ChangeLocationType>(ChangeLocationType.MoveToLocality)
  const [selectedMooringId, setSelectedMooringId] = useState<EntityId | undefined>(mainComponent.position?.mooringId)
  const [selectedDetails, setSelectedDetails] = useState<{
    selectedLocalityId?: EntityId
    selectedPositionId?: EntityId
  }>({
    selectedLocalityId: mainComponent.selectedLocalityId,
    selectedPositionId: mainComponent.positionId,
  })
  const mainComponentSchema = z.object({
    mainComponentId: z.number().default(mainComponent.id),
    selectedLocalityId: z.number(),
  })
  const changePositionSchema = z.object({
    positionId: z.number(),
  })
  const form = useZodForm(formType == ChangeLocationType.MoveToPosition ? changePositionSchema : mainComponentSchema)
  const mooringList = useMemo(
    () =>
      (data ?? []).reduce((moorings, currentValue) => {
        if (!moorings.some(mooring => mooring.id == currentValue.mooringId)) {
          moorings.push({
            id: currentValue.mooringId,
            name: currentValue.mooringName,
          } as IMooring)
        }
        return moorings
      }, [] as IMooring[]) as IMooring[],
    [data],
  )

  const handleChangePosition = async form => {
    await mutateAsync(form, {
      onSuccess: () => {
        toast.success(t('cage-change-message', { ns: 'main-component' }))
        invalidateLocalityCache.getCageStatus(customerId, form.selectedLocalityId)
        invalidateMainComponentCache.invalidateEquipment(customerId)
        invalidateMainComponentCache.getMainComponentById(customerId, mainComponent.id as EntityId)
        invalidateMainComponentCache.getMainComponents(
          customerId as EntityId,
          form.selectedLocalityId as EntityId,
          form.selectedMooringId as EntityId,
          form.selectedPositionId,
        )
        invalidationActivityLogCache.getCageActivityLogs()
        invalidatePositionCache.getCagePositionDeviations(customerId, localityId, mainComponent.positionId)
        invalidateMainComponentCache.getMainComponentById(customerId as EntityId, mainComponent?.id as EntityId)
        pop()
      },
      onError: error => {
        if (error['data'] == 'ExceedMaxCountForCage') {
          toast.error(t('exceedMaxCountForCage', { ns: 'main-component' }))
        }
      },
    })
  }

  const handleChangeLocation = useCallback(
    form => {
      sendToLocality(form, {
        onSuccess: () => {
          invalidateLocalityCache.getCageStatus(customerId, form.selectedLocalityId)
          invalidateMainComponentCache.getMainComponents(
            customerId as EntityId,
            form.selectedLocalityId as EntityId,
            form.selectedMooringId as EntityId,
            form.selectedPositionId,
          )
          invalidateMainComponentCache.getOutgoingMainComponents(customerId, form.selectedLocalityId)
          invalidateCertificateCache.getCertificatesByPosition(customerId, form.selectedPositionId)
          invalidateMainComponentCache.getMainComponentsByComponentType(
            customerId,
            form.mainComponentTypeId as EntityId,
          )
          invalidateMainComponentCache.invalidateEquipment(customerId)
          invalidateMainComponentCache.getMainComponentById(customerId as EntityId, mainComponent?.id as EntityId)
          pop()
        },
        onError: error => {
          if (error['data'] == 'ExceedMaxCountForCage') {
            toast(t('exceedMaxCountForCage', { ns: 'main-component' }), { type: 'error' })
          }
          if (error && error['data'] == NO_VALID_CERTIFICATE) {
            toast(t('NoValidCertificate', { ns: 'service-station' }), { type: 'error' })
          }
        },
      })
    },
    [mainComponent, sendToLocality],
  )

  const disabledAction = useMemo(() => {
    return formType == ChangeLocationType.MoveToPosition
      ? selectedDetails.selectedPositionId === mainComponent.positionId || !selectedMooringId
      : selectedDetails.selectedLocalityId === mainComponent.selectedLocalityId
  }, [form, formType, selectedDetails])

  if (isLoading || isLoadingPositions) return <CenteredSpinner containerProps={{ minHeight: 200, minWidth: 300 }} />

  return (
    <AppForm
      form={form}
      onSubmit={formType == ChangeLocationType.MoveToPosition ? handleChangePosition : handleChangeLocation}
    >
      <GenericDialogLayout
        title={t('change-location', { ns: 'main-component' })}
        pop={pop}
        removedCloseButton={false}
        actions={[
          <FormSubmitButton
            disabled={disabledAction}
            key="send"
            createText={t('change-location', { ns: 'main-component' })}
          />,
        ]}
        topActions={[
          <Flex.Row key={'top-action-list'} width={'100%'} px={4}>
            <FormControl key={'task-type-buttons-group'}>
              <RadioGroup
                value={formType}
                onChange={(_, v) => {
                  setFormType(v as unknown as ChangeLocationType)
                }}
                row
                name="task-type-buttons-group"
                sx={{ gap: 2 }}
              >
                <FormControlLabel
                  key={'move-to-locality'}
                  value={ChangeLocationType.MoveToLocality}
                  control={<Radio />}
                  label={`${t('move-to-locality', { ns: 'main-component' })}`}
                />
                <FormControlLabel
                  key={'move-to-position'}
                  value={ChangeLocationType.MoveToPosition}
                  control={<Radio />}
                  label={`${t('move-to-position', { ns: 'main-component' })}`}
                />
              </RadioGroup>
            </FormControl>
          </Flex.Row>,
        ]}
      >
        {formType == ChangeLocationType.MoveToPosition && (
          <Fragment>
            <Autocomplete
              onChange={(_, value) => {
                setSelectedMooringId((value as IMooring)?.id)
              }}
              options={(mooringList || []) as IMooring[]}
              autoHighlight
              getOptionLabel={o => o.name}
              defaultValue={mooringList.find(x => x.id == mainComponent.position?.mooringId)}
              renderInput={params => (
                <AppTextField
                  {...params}
                  label={t('selectMooring', { ns: 'main-component' })}
                  inputProps={{
                    ...params.inputProps,
                  }}
                />
              )}
              isOptionEqualToValue={(option, value) => option.id === value.id}
            />
            <Autocomplete
              onChange={(_, value) => {
                form.setValue('positionId', (value as IPosition)?.id)
                setSelectedDetails({ selectedPositionId: (value as IPosition)?.id })
              }}
              disabled={!selectedMooringId}
              options={(data ?? []).filter(x=>x.mooringId === selectedMooringId).sort((a, b) => parseInt(a.name) - parseInt(b.name)) as IPosition[]}
              autoHighlight
              getOptionLabel={o => getCageName(o.name)}
              defaultValue={(data ?? []).find(x => x.id == mainComponent.positionId)}
              renderOption={(props, o) => (
                <MenuItem key={o.id} value={o.id} {...props}>
                  {getCageName(o.name)}
                </MenuItem>
              )}
              renderInput={params => (
                <AppTextField
                  {...params}
                  label={t('selectCage', { ns: 'main-component' })}
                  inputProps={{
                    ...params.inputProps,
                  }}
                />
              )}
              isOptionEqualToValue={(option, value) => option.id === value.id}
            />
          </Fragment>
        )}

        {formType == ChangeLocationType.MoveToLocality && (
          <Fragment>
            <Autocomplete
              onChange={(_, value) => {
                form.setValue('selectedLocalityId', (value as ILocality).id)
                setSelectedDetails({ selectedLocalityId: (value as ILocality).id })
              }}
              defaultValue={localities?.items.find(x => x.id == mainComponent.selectedLocalityId)}
              options={(localities?.items ?? []) as ILocality[]}
              autoHighlight
              getOptionLabel={o => o.name}
              renderOption={(props, o) => (
                <MenuItem key={o.id} value={o.id} {...props}>
                  {o.name}
                </MenuItem>
              )}
              renderInput={params => (
                <AppTextField
                  {...params}
                  label={t('selectALocality', { ns: 'service-station' })}
                  inputProps={{
                    ...params.inputProps,
                  }}
                />
              )}
              isOptionEqualToValue={(option, value) => option.id === value.id}
            />
          </Fragment>
        )}
      </GenericDialogLayout>
    </AppForm>
  )
}

export default ChangeLocationDialog
