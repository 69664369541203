import React from 'react'
import { Box, Card, Stack, Typography, styled, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { IServiceStation } from '../../../generated-types/service-station'
import { GetRGBA } from 'src/utils'
import { ReactComponent as IncommingIcon } from '../../../assets/svg/incomming_service.svg'
import { ReactComponent as InServiceIcon } from '../../../assets/svg/inservice_service.svg'
import { ReactComponent as InStockIcon } from '../../../assets/svg/instock_service.svg'
import { ReactComponent as OutGoingIcon } from '../../../assets/svg/outgoing_service.svg'
import CountWithIconBox from 'src/components/common/CountWithIconBox'
import { ServiceStationType } from 'src/enums'
import { ReactComponent as PhoneIcon } from '../../../assets/svg/phoneIcon.svg'
import { ReactComponent as PersonIcon } from '../../../assets/svg/personIcon.svg'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

interface ServiceStationCardProps {
  serviceStation: IServiceStation
  className?: string
  onClick: (e) => void
  active?: boolean
}

interface StyledIconProps extends SvgIconProps {
  icon: React.ElementType<SvgIconProps>;
}

const Name = styled(Typography)(() => ({
  verticalAlign: 'center',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  width: 'auto',
  display: 'inline-block',
  fontWeight: 'bold'
}))

const PhoneSVGIcon = (props: SvgIconProps) => (
  <SvgIcon component={PhoneIcon} viewBox="0 0 10 10" {...props} />
)

const PersonSVGIcon = (props: SvgIconProps) => (
  <SvgIcon component={PersonIcon} viewBox="0 0 10 10" {...props} />
)

const StyledIcon = styled(({ icon: IconComponent, ...props }: StyledIconProps) => <IconComponent {...props} />)({
  fontSize: '12px',
  marginRight: '2px'
})

const StyledCard = styled((Card))(({ theme }) => ({
  '&.MuiPaper-root': {
    backgroundColor: theme.palette.inputSearch[theme.palette.mode],
    color: theme.palette.primaryDark[theme.palette.mode],
    boxShadow: `0px 0px 6px ${GetRGBA(theme.palette.black[theme.palette.mode], 0.29)}`
  },
  padding: '9px 8px 9px 8px',
  overflow: 'hidden',
  display: 'flex',
  height : '130px'
}))

const ServiceStationCard: React.FC<ServiceStationCardProps> = ({ serviceStation, className, onClick, active }) => {
  const { t } = useTranslation('main-component')
  const theme = useTheme()
  return (
    <Box sx={{ padding: '6px 8px' }} >
      <Box className={className}>
        <StyledCard style={{ cursor: 'pointer', backgroundColor: active ? theme.palette.primaryMain[theme.palette.mode] : theme.palette.inputSearch[theme.palette.mode], color: active ? theme.palette.white[theme.palette.mode] : theme.palette.primaryDark[theme.palette.mode] }} onClick={onClick}>
          <Box className='pl-12-core' flexGrow={1} flexShrink={1} flexBasis="0" sx={{ overflow: 'hidden', width: '100%' }}>
            <Stack direction={'row'} alignItems={'center'} maxWidth={'100%'} sx={{ overflow: 'hidden', width: '100%' }}>
              <Name variant='subtitle1'>
                {serviceStation.name}
              </Name>
              <Typography sx={{ fontWeight: 500, paddingLeft: '4px' }} width={'30%'} variant='body2'>
                {serviceStation.serviceStationNumber && '#'}{serviceStation.serviceStationNumber}
              </Typography>
            </Stack>

            <Stack direction={'column'} justifyContent={'left'}>
              {(serviceStation.managedByUser?.firstName || serviceStation.managedByUser?.lastName) &&
                (<Stack sx={{ paddingLeft: '2px', display: 'flex', alignItems: 'center', justifyContent: 'left', overflow: 'hidden' }} direction={'row'}>
                  <StyledIcon height={10} width={10} icon={PersonSVGIcon} />
                  <Name variant='caption'>{`${serviceStation.managedByUser?.firstName || ''} ${serviceStation.managedByUser?.lastName || ''}`}</Name>
                </Stack>)}

                {serviceStation.managedByUser?.phoneNumber &&
                (<Stack sx={{ paddingLeft: '2px', display: 'flex', alignItems: 'center', justifyContent: 'left' }} direction={'row'}>
                  <StyledIcon height={10} width={10} icon={PhoneSVGIcon} />
                  <Typography sx={{ paddingLeft: '2px' }} variant='caption'>{`${serviceStation.managedByUser?.phoneNumber || ''}`}</Typography>
                </Stack>)}
            </Stack>

            <Stack sx={{ paddingTop: '11px' }} direction={'row'}>
              {(serviceStation?.type == ServiceStationType.ServiceStation || serviceStation?.type == ServiceStationType.Service) && (
                <Box>
                  <CountWithIconBox
                    title={t('incoming')}
                    Component={IncommingIcon}
                    value={serviceStation.incomingCount ?? 0}
                    iconBgColor={theme.palette.successMain[theme.palette.mode]}
                    color={active ? theme.palette.white[theme.palette.mode] : theme.palette.black[theme.palette.mode]}
                  />
                </Box>)}

              {(serviceStation?.type == ServiceStationType.ServiceStation || serviceStation?.type == ServiceStationType.Service) && (
                <Box sx={{ paddingLeft: '8px' }}>
                  <CountWithIconBox
                    title={t('in-service')}
                    Component={InServiceIcon}
                    value={serviceStation.inServiceCount ?? 0}
                    iconBgColor={theme.palette.primaryDark[theme.palette.mode]}
                    color={active ? theme.palette.white[theme.palette.mode] : theme.palette.black[theme.palette.mode]}
                  />
                </Box>)}

              {(serviceStation?.type == ServiceStationType.ServiceStation || serviceStation?.type == ServiceStationType.Storage) && (
                <Box sx={{ paddingLeft: '8px' }}>
                  <CountWithIconBox
                    title={t('in-storage')}
                    Component={InStockIcon}
                    value={serviceStation.inStockCount ?? 0}
                    iconBgColor={theme.palette.infoDark[theme.palette.mode]}
                    color={active ? theme.palette.white[theme.palette.mode] : theme.palette.black[theme.palette.mode]}
                  />
                </Box>)}

              <Box sx={{ paddingLeft: '8px' }}>
                <CountWithIconBox
                  title={t('outgoing')}
                  Component={OutGoingIcon}
                  value={serviceStation?.outgoingCount ?? 0}
                  iconBgColor={theme.palette.successDark[theme.palette.mode]}
                  color={active ? theme.palette.white[theme.palette.mode] : theme.palette.black[theme.palette.mode]}
                />
              </Box>
            </Stack>
          </Box>
        </StyledCard>
      </Box>
    </Box>
  )
}

export default ServiceStationCard