import { Box, Stack, SvgIconTypeMap, Typography, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import React from 'react'
import { OverridableComponent } from '@mui/material/OverridableComponent'
import CommonFile from 'src/assets/Common 2@2x.png'

interface EmptyStatusCardProps {
  image?: string | OverridableComponent<SvgIconTypeMap<any, 'svg'>> | undefined
  header?: string
  content?: string
  imageWidth: string
  height?: string
  headerFontSize?: string
  contentFontSize?: string
  imageBottomMargin?: string
  imageTopMargin?: string
  contentLineHeight?: string
  contentTopMargin?: string
}

export const EmptyStatusCard: React.FC<EmptyStatusCardProps> = ({ image = CommonFile, header = 'data-unavailable', content = 'data-unavailable-description', imageWidth, height, headerFontSize, contentFontSize, imageBottomMargin, contentLineHeight, contentTopMargin, imageTopMargin }) => {
  const { t: translate } = useTranslation(['common'])
  const theme = useTheme()

  return (
    <Box display={'flex'} justifyContent={'center'} alignItems={'center'} height={height}>
      <Stack direction={'column'} className="mt-10" display={'flex'} justifyContent={'center'} alignItems={'center'}>
        <Box marginBottom={imageBottomMargin} marginTop={imageTopMargin} className="p-8 pt-0" sx={{ fontSize: imageWidth }}>
          <img width={imageWidth} src={image as string}  />
        </Box>
        <Stack direction={'column'} className="p-5 pt-0" display={'flex'} alignItems={'top'} justifyContent={'center'}>
          <Typography marginBottom={'-20px'} variant="h5" color={theme.palette.primaryDark[theme.palette.mode]} textAlign="center" fontSize={headerFontSize}>
            {translate(header, { ns: 'common' })}
          </Typography>
          <Typography
            maxWidth={'600px'}
            className="pt-4"
            variant="subtitle1"
            fontWeight={400}
            lineHeight={contentLineHeight}
            marginTop={contentTopMargin}
            fontSize={contentFontSize}
            color={theme.palette.black[theme.palette.mode]}
            textAlign="center"
          >
            {translate(content, { ns: 'common' })}
          </Typography>
        </Stack>
      </Stack>
    </Box>
  )
}
