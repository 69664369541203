import { DocumentExpirationType } from 'src/enums'
import { ICreateDocument, IDocument, IDocumentHistory, IUpdateDocument } from '../generated-types/document'
import { IMooringDocuments } from '../generated-types/mooring-documents'
import { queryClient } from '../query-client'
import { IPaginatedResult } from './paginated-result'
import { useDelete, useGet, usePost, usePut } from './reactQuery'
import { documentQueryKeys } from 'src/query-keys'

const baseUrl = (customerId: EntityId) => `customers/${customerId}/documents`

export const documentEndpoints = {
  getDocuments(customerId: EntityId) {
    return `${baseUrl(customerId)}`
  },
  getDocument(customerId: EntityId, id: EntityId) {
    return `${baseUrl(customerId)}/${id}`
  },
  createDocument(customerId: EntityId) {
    return `${baseUrl(customerId)}`
  },
  updateDocument(customerId: EntityId, id: EntityId) {
    return `${baseUrl(customerId)}/${id}`
  },
  deleteDocument(customerId: EntityId) {
    return `${baseUrl(customerId)}`
  },
  getDocumentHistory(customerId: EntityId, id: EntityId) {
    return `${baseUrl(customerId)}/${id}/history`
  },
  getDocumentByFileId(customerId: EntityId, fileId: EntityId) {
    return `${baseUrl(customerId)}/get-by-fileId/${fileId}`
  },
  updateDocumentByFileId(customerId: EntityId, fileId: EntityId) {
    return `${baseUrl(customerId)}/update-by-fileId/${fileId}`
  },
  getMooringDocuments(customerId: EntityId, localityId: EntityId, mooringId: EntityId) {
    return `${baseUrl(customerId)}/locality/${localityId}/mooring/${mooringId}`
  },
  getExpireCounts(customerId: EntityId) {
    return `${baseUrl(customerId)}/expire-counts`
  },
}

export const invalidateDocument = {
  getDocumentByFileId(customerId: EntityId, fileId: EntityId) {
    queryClient.invalidateQueries(documentEndpoints.getDocumentByFileId(customerId, fileId))
  },
  getDocuments(customerId: EntityId) {
    queryClient.invalidateQueries(documentEndpoints.getDocuments(customerId))
  },
  getDocumentsHistory(customerId: EntityId, documentId: EntityId) {
    queryClient.invalidateQueries(documentEndpoints.getDocumentHistory(customerId, documentId))
  },
  getExpireCounts(customerId?: EntityId, localityId?: EntityId) {
    queryClient.invalidateQueries(documentQueryKeys.GET_DOCUMENT_EXPIRATION_TYPES(customerId, localityId))
  },
}

export interface IGetDocumentsParams {
  customerId: EntityId
  localityId?: EntityId
  documentCategoryId?: EntityId
  mainComponentId?: EntityId
  mooringId?: EntityId
  positionId?: EntityId
  componentId?: EntityId
  deviationId?: EntityId
  deviationFollowUpId?: EntityId
  localityFrequencyId?: EntityId
  documentExpirationTypesArray?: DocumentExpirationType[]
}

export const useGetDocuments = (customerId: EntityId, params: IGetDocumentsParams, page = 0, pageSize = 1000) =>
  useGet<IPaginatedResult<IDocument>>(documentEndpoints.getDocuments(customerId), {
    ...params,
    page,
    pageSize,
  })

export const useGetDocumentHistory = (
  customerId: EntityId,
  documentId: EntityId,
  startDate?: Date,
  endDate?: Date,
  page?: number,
  pageSize?: number,
) =>
  useGet<IPaginatedResult<IDocumentHistory>>(documentEndpoints.getDocumentHistory(customerId, documentId), {
    startDate,
    endDate,
    page,
    pageSize,
  })

export const useGetDocument = (customerId: EntityId, id: EntityId) =>
  useGet<IDocument>(documentEndpoints.getDocument(customerId, id))

export const useGetExpireCount = (customerId: EntityId, localityId: EntityId) =>
  useGet<{ expiredCount: number; expiringCount: number }>(
    documentEndpoints.getExpireCounts(customerId),
    {
      localityId: localityId,
    },
    { queryKey: documentQueryKeys.GET_DOCUMENT_EXPIRATION_TYPES(customerId, localityId) },
  )

export const useGetDocumentByFileId = (customerId: EntityId, fileId: EntityId) =>
  useGet<IDocument>(documentEndpoints.getDocumentByFileId(customerId, fileId))

export const useAddDocument = (customerId: EntityId) =>
  usePost<IDocument, ICreateDocument>(documentEndpoints.createDocument(customerId))

export const useUpdateDocument = (customerId: EntityId, id: EntityId) =>
  usePut<IDocument, IUpdateDocument>(documentEndpoints.updateDocument(customerId, id))

export const useUpdateDocumentByFileId = (customerId: EntityId, fileId: EntityId) =>
  usePut<IDocument, IUpdateDocument>(documentEndpoints.updateDocumentByFileId(customerId, fileId))

export const useDeleteDocument = (customerId: EntityId) =>
  useDelete<IDocument>(documentEndpoints.deleteDocument(customerId))

export const useGetMooringDocuments = (customerId: EntityId, localityId: EntityId, moorringId: EntityId) =>
  useGet<IMooringDocuments[]>(documentEndpoints.getMooringDocuments(customerId, localityId, moorringId))
