import { Button, List } from '@mui/material'
import { Box } from '@mui/material'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { AppRoutesNavigation } from '../../app-routes'
import { customerSidebarItems } from './customer-sidebar-items'
import { useSidebarCtx } from '../../hooks/context-hooks'
import { SidebarHeaderItem } from '../../components/SidebarHeaderItem'
import { SidebarItem } from '../../components/SidebarItem'
import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'

export const CustomerSidebar = () => {
  const { id } = useParams<any>()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { t: translate } = useTranslation(['admin-sidebar', 'service'])
  const { setCollapsibleSidebarOpen, collapsibleSidebarOpen } = useSidebarCtx()

  useEffect(() => {
    setCollapsibleSidebarOpen(true)
  }, [])

  return (
    <Box sx={{ paddingTop: collapsibleSidebarOpen ? '0px' : '90px' }}>
      <Box display={collapsibleSidebarOpen ? 'block' : 'none'} pt={2} className="px-1 py-1 lg:pl-1">
        <Button
          variant="contained"
          color="primary"
          className="w-full"
          sx={{
            fontSize: {
              xs: '15px',
              sm: '15px',
              lg: '20px',
            },
            textTransform: 'none',
            fontWeight: 'bold',
          }}
        >
          {translate('administrator')}
        </Button>
      </Box>

      <List style={{ width: collapsibleSidebarOpen ? '100%' : '72px' }} className="!px-1 !py-1 lg:!pl-1">
        {customerSidebarItems.map(item => {
          const isHeader = item?.children && item.children?.length > 0
          return (
            <div key={item.name}>
              {isHeader && (
                <SidebarHeaderItem item={item} textVisibility={collapsibleSidebarOpen} title={item.name} Icon={item.icon} id={id} pathname={pathname} />
              )}
              {!isHeader && (
                <SidebarItem
                  key={item.name}
                  active={!!pathname && pathname.split('/')[4] === item.pathname}
                  title={translate(item.name)}
                  Icon={item.icon}
                  textVisibility={collapsibleSidebarOpen}
                  onClick={() => navigate(item.path ? item.path(id!) : AppRoutesNavigation.Home)}
                />
              )}
            </div>
          )
        })}
      </List>
    </Box>
  )
}
