import {
  Toolbar,
  IconButton,
  Typography,
  Avatar,
  Divider,
  ListItemIcon,
  Menu,
  MenuItem,
  Stack,
  Box,
  useTheme,
  useMediaQuery,
} from '@mui/material'
import logo from '../../assets/lovold_logo.png'
import { To } from 'history'
import { useLocation, useNavigate } from 'react-router'
import { useTranslation } from 'react-i18next'
import { useMemo, useState } from 'react'
import { AdminPanelSettings, AdminPanelSettingsOutlined, Logout, MenuSharp, NotificationsOutlined, Settings } from '@mui/icons-material'
import { AppRoutesNavigation } from '../../app-routes'
import { useSidebarCtx, useUserCtx } from '../../hooks/context-hooks'
import { mainTopBarItems } from '../../screens/main-top-bar-items'
import { userManager } from '../../user-manager'
import { LogoContainer, NavigationContainer, PaperProperties, ProfileAvatar, TopBarIcons } from './Index.style'
import { Flex } from '../../styles/flexComponent'
import { permissionValue } from 'src/enums/permissionValue'
import LanguageIconMenu, { OptionsProps } from 'src/components/LanguageIconMenu'
import i18n from 'src/i18n'
import { useLocalStorage } from 'src/hooks/local-storage'
import { languages } from 'src/utils/languages'
import { getThemeColor } from 'src/utils'
import { MUIThemeColors } from 'src/mui-theme'
import { Padding } from 'src/styles/paddingComponent'

const SYSTEM_ADMIN_PERMISSION = BigInt(permissionValue.SystemAdminPermission)

export const TopBar = () => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { setCollapsibleSidebarOpen } = useSidebarCtx()
  const { t: translate } = useTranslation(['header', 'common'])
  const { user } = useUserCtx()
  const [_, setStorageItem] = useLocalStorage('languageCode', 'en')
  const isSidebarHidden = ['locality', 'service-station'].includes(pathname.split('/')[1])
  const theme = useTheme()
  const isLarge = useMediaQuery<any>(theme.breakpoints.up(2000))


  const handleNavigate = (path: To) => {
    if (path == AppRoutesNavigation.Vessel) window.location.href = path
    navigate(path)
  }

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const onLogout = () => {
    userManager.signoutRedirect()
  }

  function changeGlobalLanguage(e) {
    const lang = e.target.value.toString()
    i18n.changeLanguage(lang)
    setStorageItem(lang)
  }

  const userFirstLetters = user ? (user?.firstName?.charAt(0) || '') + (user?.lastName?.charAt(0) || '') : '-'
  const isSuperAdmin = useMemo(() => (user && (user?.permission > 0) || ((BigInt(user?.permission ?? 0) & SYSTEM_ADMIN_PERMISSION) === SYSTEM_ADMIN_PERMISSION)), [user])

  return (
    <>
      <Toolbar>
        {!isSidebarHidden && !isLarge && <MenuSharp fontSize={'large'} onClick={() => setCollapsibleSidebarOpen(true)} />}
        <LogoContainer sx={{ paddingLeft: { lg: '12px', md: '12px', sm: '12px', xs: '12px' } }}>
          <img width={193} src={logo} />
        </LogoContainer>
        <Flex.Row spacing={1} justifyContent={'flex-end'} height={80}>
          <Stack direction={'row'} paddingTop={'10px'}>
            <Flex.Row paddingRight={'10px'}>
              {mainTopBarItems.map((nav, index) => {
                const isActive = !!pathname && [nav.path, nav.main].includes(`/${pathname.split('/')[1]}`)
                return <NavigationContainer
                  key={index}
                  onClick={() => {
                    handleNavigate(nav.path)
                  }}
                  sx={{ display: { xs: 'none', sm: 'flex' } }}
                  active={isActive}
                >
                  <nav.Icon style={{ color: getThemeColor(theme, MUIThemeColors.primaryMain) }} />
                  <Typography color={isActive ? getThemeColor(theme, MUIThemeColors.primaryMain) : getThemeColor(theme, MUIThemeColors.black)}
                    variant="subtitle1" marginLeft={1} paddingTop={'9px'} fontWeight={600} sx={{ display: { sm: 'none', md: 'none', lg: 'flex' } }}>
                    {translate(nav.name)}
                  </Typography>
                </NavigationContainer>
              })}
            </Flex.Row>
            <Stack direction={'row'} paddingTop={'10px'}>
              <TopBarIcons>
                {/* need to implement the functionality later. */}
                <NotificationsOutlined sx={{ fontSize: '44px', color: theme.palette.primaryMain[theme.palette.mode] }} />
              </TopBarIcons>
              {isSuperAdmin && (
                <TopBarIcons
                  sx={{
                    display: { xs: 'none', lg: 'flex' },
                    marginLeft: '20px',
                    marginRight: '20px',
                  }}
                >
                  <AdminPanelSettingsOutlined
                    sx={{ fontSize: '44px', color: theme.palette.primaryMain[theme.palette.mode] }}
                    onClick={() => navigate(AppRoutesNavigation.Admin.customers)}
                  />
                </TopBarIcons>
              )}
            </Stack>
          </Stack>
          <Padding.pr16 paddingLeft={'4px'}>
            <LanguageIconMenu name="language" options={languages as OptionsProps[]} onChange={changeGlobalLanguage} />
          </Padding.pr16>
          <Box paddingTop={'12px'}>
            <IconButton
              onClick={handleClick}
              size="small"
              aria-controls={open ? 'account-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
            >
              <Avatar src={user?.profileImage} sx={{ width: 36, height: 36 }}>
                {user?.profileImage == undefined ? userFirstLetters : ''}
              </Avatar>
            </IconButton>
          </Box>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={PaperProperties}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            <MenuItem sx={{ p: 1, display: { xs: 'none', sm: 'flex' } }}>
              {user?.firstName} {user?.lastName}
            </MenuItem>
            <Divider sx={{ display: { xs: 'none', sm: 'flex' } }} />
            <MenuItem onClick={handleClose} sx={{ p: 1.5 }}>
              <ProfileAvatar src={user?.profileImage}>
                {user?.profileImage == undefined ? userFirstLetters : ''}
              </ProfileAvatar>
              {translate('manageProfile', { ns: 'header' })}
            </MenuItem>
            <MenuItem onClick={handleClose} sx={{ display: { xs: 'flex', lg: 'none' }, p: 1.5 }}>
              <ListItemIcon>
                <AdminPanelSettings fontSize="small" />
              </ListItemIcon>
              {translate('superAdmin', { ns: 'common' })}
            </MenuItem>
            <MenuItem onClick={handleClose} sx={{ p: 1.5 }}>
              <ListItemIcon>
                <Settings fontSize="small" />
              </ListItemIcon>
              {translate('settings', { ns: 'common' })}
            </MenuItem>
            <MenuItem onClick={onLogout} sx={{ p: 1.5 }}>
              <ListItemIcon>
                <Logout fontSize="small" />
              </ListItemIcon>
              {translate('logout', { ns: 'header' })}
            </MenuItem>
          </Menu>
        </Flex.Row>
      </Toolbar>
    </>
  )
}
