import { Box, PaginationItem, useMediaQuery, useTheme } from '@mui/material'
import {
  DataGridProProps,
  GridCallbackDetails,
  GridCellParams,
  GridEventListener,
  GridInitialState,
  GridInputSelectionModel,
  GridRowClassNameParams,
  GridRowHeightParams,
  GridRowHeightReturnValue,
  GridRowId,
  GridRowParams,
  GridSelectionModel,
  GridSlotsComponent,
  GridSortModel,
  GridToolbar,
} from '@mui/x-data-grid-pro'
import { ReactElement, memo, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { QueryKeyT, useGet } from '../../api/reactQuery'
import { IBaseEntity } from '../../generated-types'
import { PaginatedResultList } from '../../generated-types/paginated-result-list'
import { GridApiPro } from '@mui/x-data-grid-pro/models/gridApiPro'
import { QuickFilterBounceTime } from '../../constant'
import { NextArrow, PreviousArrow } from './PaginationArrows'
import { Spinner } from '../Spinner'
import MobileCardList from './MobileCardList'
import { ArrowDropDown, ArrowRight } from '@mui/icons-material'
import { PaginationComponent, StyledDataGridPro } from './GenericTable.style'

interface ICustomDataGridWithPagination extends Omit<DataGridProProps,'rows'> {
  queryUrl: string
  queryParams?: object
  enablePagination: boolean
  columns: any[]
  initialPage: number
  initialState?: GridInitialState
  pageOptions: number[]
  pageSize: number
  setPageSize: (pageSize: number) => void
  onCellClick?: GridEventListener<'cellClick'> | undefined
  noRowsMessage?: string
  isRowHightAuto?: boolean
  onSelectionModelChange?: ((selectionModel: GridSelectionModel, details: GridCallbackDetails<any>) => void) | undefined
  onRowClick?: GridEventListener<'rowClick'> | undefined
  onCellEditCommit?: GridEventListener<'cellEditCommit'> | undefined
  isCellEditable?: ((params: GridCellParams<any, any, any>) => boolean) | undefined
  selectionModel?: GridInputSelectionModel | undefined
  isRowSelectable?: (params: GridRowParams<any>) => boolean
  getRowHeight?: ((params: GridRowHeightParams) => GridRowHeightReturnValue) | undefined
  apiRef?: React.MutableRefObject<GridApiPro>
  loading?: boolean
  enableFilter?: boolean
  disableSelectionOnClick?: boolean
  sortModel?: GridSortModel | undefined
  enableQuickFilter?: boolean
  getRowClassName?: (params: GridRowClassNameParams<IBaseEntity>) => string
  getDetailPanelContent?: ((params: GridRowParams<any>) => React.ReactNode) | undefined
  getDetailPanelHeight?: ((params: GridRowParams<any>) => number | 'auto') | undefined
  enablePaginationButtons?: {
    showFirstButton?: boolean
    showLastButton?: boolean
  }
  onDetailPanelExpandedRowIdsChange?: ((ids: GridRowId[], details: GridCallbackDetails<any>) => void) | undefined
  detailPanelExpandedRowIds?: GridRowId[] | undefined
  generateQueryKey?: (page: number, pageSize: number) => QueryKeyT | undefined
  hideDefaultFooterPagination?: boolean
  customStyles?: any
  hideFooterSelectedRowCount?: boolean
  rowTopSpacing?: number
  rowBottomSpacing?: number
  components?: Partial<GridSlotsComponent> | undefined
  className?: any
  mobileViewCard?: (row: any) => ReactElement
  height?: number | string
  autoHeight?: boolean
  isViewAllRecords?: boolean
  isMinifiedColumnHeader?: boolean
}
export const CustomDataGridWithPagination: React.FC<ICustomDataGridWithPagination> = memo( ({
  queryUrl,
  queryParams,
  enablePagination,
  columns,
  initialPage,
  pageOptions,
  pageSize,
  setPageSize,
  onCellClick,
  noRowsMessage,
  initialState,
  isRowHightAuto = false,
  children,
  onSelectionModelChange,
  onRowClick,
  onCellEditCommit,
  isRowSelectable,
  getRowHeight,
  apiRef,
  loading = false,
  enableFilter = false,
  disableSelectionOnClick,
  sortModel,
  enableQuickFilter = false,
  getDetailPanelHeight,
  getDetailPanelContent,
  enablePaginationButtons,
  detailPanelExpandedRowIds,
  onDetailPanelExpandedRowIdsChange,
  generateQueryKey,
  hideDefaultFooterPagination,
  customStyles,
  hideFooterSelectedRowCount,
  rowTopSpacing,
  rowBottomSpacing,
  components,
  mobileViewCard,
  className,
  height,
  autoHeight = true,
  getRowClassName,
  isViewAllRecords,
  isMinifiedColumnHeader,
  ...props
}) => {
  const [page, setPage] = useState(initialPage ?? 0)
  const [defaultPageSize, setDefaultPageSize] = useState(pageSize)
  const { t } = useTranslation(['common'])
  const theme = useTheme()
  const isSmall = useMediaQuery<any>(theme.breakpoints.down('sm'))

  let parsedProps = { ...props }

  if (isRowHightAuto) {
    parsedProps = {
      ...props,
    }
  }

  const { data: serverData, isLoading,refetch } = useGet<PaginatedResultList<IBaseEntity>>(
    `${queryUrl}`,
    {
      page,
      pageSize: defaultPageSize,
      ...queryParams,
    },
    { queryKey: generateQueryKey && generateQueryKey(page, defaultPageSize) },
  )

  useEffect(() => { 
    if (serverData?.totalCount && serverData?.totalCount != defaultPageSize && serverData?.totalCount > defaultPageSize  && ((height && !enablePagination && !autoHeight) || isViewAllRecords)) {
      setDefaultPageSize(serverData.totalCount)
    }
  }, [serverData?.totalCount, autoHeight, enablePagination, height])
  
  useEffect(() => { 
    refetch()
  }, [defaultPageSize])

  const handlePageChange = (_, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = useCallback((event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setPageSize(parseInt(event.target.value, 10))
    setPage(0)
  }, [])

  return (
    <>
      <Box sx={{ height: height }}>
        {isSmall ? (
          <MobileCardList
            columns={columns}
            rows={serverData?.items ?? []}
            mobileViewCard={mobileViewCard}
            getDetailPanelContent={getDetailPanelContent}
            onRowClick={onRowClick}
          />
        ) : (
          <StyledDataGridPro
            id="custom-data-grid"
            columns={columns}
            rows={serverData?.items ?? []}
            rowCount={serverData?.totalCount ?? 0}
            autoHeight={autoHeight}
            disableColumnFilter={!enableFilter}
            disableDensitySelector
            disableMultipleColumnsSorting
            disableColumnMenu
            disableMultipleSelection
            disableColumnSelector
            disableColumnResize
            loading={isLoading}
            hideFooter={!enablePagination}
            pagination={enablePagination}
            rowsPerPageOptions={pageOptions}
            pageSize={defaultPageSize}
            page={page}
            onPageChange={page => setPage(page)}
            onPageSizeChange={newPageSize => setPageSize(newPageSize)}
            getRowClassName={Params => (getRowClassName ? getRowClassName(Params) : 'bg-white ')}
            initialState={initialState}
            onCellDoubleClick={onCellClick}
            onRowClick={onRowClick}
            components={{
              ...components,
              Toolbar: GridToolbar,
              NoResultsOverlay: () => (
                <Box className="font-semibold text-center text-gray-600 text-l">
                  {noRowsMessage ?? t('noResultFound')}
                </Box>
              ),
              NoRowsOverlay: () => (
                <Box
                  className="font-semibold text-center text-gray-600 text-l"
                  display={'flex'}
                  flexDirection={'column'}
                  alignItems={'center'}
                  p={6}
                >
                  {noRowsMessage ?? t('noResultFound')}
                </Box>
              ),
              LoadingOverlay: () => {
                return (
                  <Box height={'100%'} width={'100%'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                    <Spinner />
                  </Box>
                )
              },
              DetailPanelExpandIcon: ArrowRight,
              DetailPanelCollapseIcon: ArrowDropDown,
            }}
            componentsProps={{
              pagination: {
                count: serverData?.totalCount ?? 0,
                page: page,
                onPageChange: handlePageChange,
                onRowsPerPageChange: handleChangeRowsPerPage,
                rowsPerPage: defaultPageSize,
                rowsPerPageOptions: pageOptions,
                showFirstButton: enablePaginationButtons?.showFirstButton ?? false,
                showLastButton: enablePaginationButtons?.showLastButton ?? false,
              },
              toolbar: {
                printOptions: { disableToolbarButton: true },
                csvOptions: { disableToolbarButton: true },
                showQuickFilter: enableQuickFilter,
                quickFilterProps: { debounceMs: QuickFilterBounceTime },
              },
            }}
            getRowSpacing={_ => {
              return {
                top: rowTopSpacing ?? 0,
                bottom: rowBottomSpacing ?? 0,
              }
            }}
            paginationMode="server"
            {...parsedProps}
            className={`${customStyles ?? ''} ${className ?? ''} scrollbar`}
            onSelectionModelChange={onSelectionModelChange}
            onCellEditCommit={onCellEditCommit}
            onCellClick={onCellClick}
            isRowSelectable={isRowSelectable}
            getRowHeight={getRowHeight}
            apiRef={apiRef}
            disableSelectionOnClick={disableSelectionOnClick}
            sortModel={sortModel}
            getDetailPanelContent={getDetailPanelContent}
            getDetailPanelHeight={getDetailPanelHeight}
            detailPanelExpandedRowIds={detailPanelExpandedRowIds}
            onDetailPanelExpandedRowIdsChange={onDetailPanelExpandedRowIdsChange}
            hideFooterPagination={hideDefaultFooterPagination}
              hideFooterSelectedRowCount={hideFooterSelectedRowCount}
            isMinifiedColumnHeader={isMinifiedColumnHeader}
            {...props}
          />
        )}
      </Box>
      {serverData && enablePagination && (hideDefaultFooterPagination || isSmall) && serverData.items.length != 0 && (
        <Box display={'flex'} justifyContent={'center'} alignItems={'center'} padding={'8px'}>
          <PaginationComponent
            size="small"
            count={(serverData && Math.ceil(serverData?.totalCount / defaultPageSize)) ?? 0}
            page={page + 1}
            onChange={(event, page) => handlePageChange(event, page - 1)}
            renderItem={item => (
              <PaginationItem
                components={{ next: NextArrow, previous: PreviousArrow }}
                {...item}
              />
            )}
          />
        </Box>
      )}
    </>
  )
})
