import { Autocomplete, Button, Divider, MenuItem } from '@mui/material'
import React, { Fragment, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useGetFile } from '../../../api/file'
import { useGetMainComponentCustomProperties, useGetMainComponentTypeList } from '../../../api/main-component-type'
import { AppForm, AppFormProps, FormTextField } from '../../../components/FormCore'
import { ICustomProperty } from '../../../generated-types/customProperty'
import { IMainComponent } from '../../../generated-types/main-component'
import { useZodForm } from '../../../hooks/zod-form'
import { CustomMainComponentFields } from './CustomMainComponentFields'
import MainComponentStaticFormFields from './MainComponentStaticFormFields'
import { addCustomFields, getMainComponentSchema } from './mainComponentSchema'
import { IFormType } from '../../../generated-types/form-type'
import { GenericDialogLayout } from '../../../components/GenericDialogLayout'
import { IFile } from '../../../generated-types'
import { AppTextField } from 'src/components/AppTextField'
import { getTranslatedText } from 'src/utils'
import { IMainComponentType } from 'src/generated-types/main-component-type'
import { IMainComponentType as Type } from 'src/enums'
import { CenteredSpinner } from 'src/components/Spinner'

interface MainComponentFormProps extends AppFormProps<IMainComponent> {
  onSubmitAlter?: (form: any, isCloneable: boolean) => void
  title: string
  customerId: EntityId
}

export const MainComponentForm: React.FC<MainComponentFormProps> = ({
  onSubmit,
  onCancel,
  initialValues,
  onSubmitAlter,
  title,
  customerId,
}) => {
  const { data: customFields } = useGetMainComponentCustomProperties(
    initialValues?.typeId as EntityId,
    customerId as EntityId,
  )
  const { data: mainComponentTypes, isLoading } = useGetMainComponentTypeList(customerId as EntityId)
  const { data } = useGetFile(initialValues?.fileId)
  const { data: certificateFileData } = useGetFile(initialValues?.certificate?.fileId)

  const [initialCertificateFiles, setInitialCertificateFiles] = useState<IFile | null>()

  const [mainComponentTypeDetails, setMainComponentTypeDetails] = useState<{
    mainComponentType: IMainComponentType | undefined
    isCertificateDetailsRequired: boolean
  }>({
    mainComponentType: mainComponentTypes?.find(type => type.id === initialValues?.typeId) as IMainComponentType,
    isCertificateDetailsRequired:
      mainComponentTypes?.find(type => type.id === initialValues?.typeId)?.componentType == Type.Net,
  })

  const isUpdateForm = initialValues?.id

  const { t: translate } = useTranslation(['main-component', 'common'])

  useEffect(() => {
    if (!initialValues?.typeId) {
      setMainComponentTypeDetails({
        mainComponentType: mainComponentTypes?.[0] as IMainComponentType,
        isCertificateDetailsRequired: mainComponentTypes?.[0].componentType == Type.Net,
      })
      return
    }

    setMainComponentTypeDetails({
      mainComponentType: mainComponentTypes?.find(type => type.id === initialValues?.typeId) as IMainComponentType,
      isCertificateDetailsRequired:
        mainComponentTypes?.find(type => type.id === initialValues?.typeId)?.componentType == Type.Net,
    })
  }, [initialValues?.typeId, mainComponentTypes, isLoading])

  const form = useZodForm(
    (customFields as Array<ICustomProperty>)?.length > 0
      ? addCustomFields(
          isUpdateForm
            ? getMainComponentSchema(IFormType.Update, translate)
            : getMainComponentSchema(
                IFormType.Create,
                translate,
                mainComponentTypeDetails.isCertificateDetailsRequired,
              ),
          customFields,
        )
      : isUpdateForm
      ? getMainComponentSchema(IFormType.Update, translate)
      : getMainComponentSchema(IFormType.Create, translate, mainComponentTypeDetails.isCertificateDetailsRequired),
    {
      defaultValues: {
        ...initialValues,
        ...JSON.parse(initialValues?.customPropertyValues || '{}'),
      },
    },
  )

  const handleSubmit = useCallback(
    async (f, isCloneable = false) => {
      const {
        certificateId,
        certificateNumber,
        certificateDate,
        validUntil,
        certificateDocumentType,
        certificateFileId,
        certificateSupplierId,
        ...rest
      } = f

      if (mainComponentTypeDetails.isCertificateDetailsRequired) {
        rest.certificate = {
          id: certificateId,
          certificateNumber,
          certificateDate,
          validUntil,
          certificateDocumentType,
          fileId: certificateFileId,
          supplierId: certificateSupplierId,
          mainComponentId: f.id,
        }
      }

      const customProperties = new Map<string, any>()
      customFields
        ?.map(field => JSON.parse(field.label as unknown as string).en.toLowerCase())
        .forEach(async key => {
          customProperties.set(key, f[key])
        })

      if (isCloneable) {
        form.setValue('serialNumber', '')
        form.setValue('name', '')
        form.setValue('certificateNumber', '')
        form.setValue('certificateSupplierId', null)
        form.setValue('certificateDate', null)
        form.setValue('validUntil', null)
        form.setValue('certificateFileId', null)
        setInitialCertificateFiles(null)
        onSubmitAlter &&
          onSubmitAlter(
            { ...rest, customPropertyValues: JSON.stringify(Object.fromEntries(customProperties)) },
            isCloneable,
          )
      } else {
        onSubmit({ ...rest, customPropertyValues: JSON.stringify(Object.fromEntries(customProperties)) })
      }
    },
    [onSubmitAlter, form, customFields, mainComponentTypeDetails.isCertificateDetailsRequired],
  )

  useEffect(() => {
    setInitialCertificateFiles(certificateFileData)
  }, [certificateFileData])

  useEffect(() => {
    if (mainComponentTypeDetails.mainComponentType) {
      form.setValue('typeId', mainComponentTypeDetails.mainComponentType.id)
    }
  }, [mainComponentTypeDetails])

  if (isLoading) {
    return <CenteredSpinner containerProps={{ height: 400, width: 600 }} />
  }

  return (
    <GenericDialogLayout
      title={title}
      pop={onCancel}
      actions={[
        <Fragment key={'main-component-form-actions'}>
          {!isUpdateForm && (
            <Button
              variant="contained"
              onClick={() => {
                form.handleSubmit(f => {
                  handleSubmit(f, true)
                })()
              }}
            >
              {translate('add-clone')}
            </Button>
          )}
          <Button
            variant="contained"
            onClick={() => {
              form.handleSubmit(handleSubmit)()
            }}
          >
            {isUpdateForm ? translate('update', { ns: 'common' }) : translate('add', { ns: 'common' })}
          </Button>
        </Fragment>,
      ]}
      removedCloseButton={false}
    >
      <AppForm form={form} onSubmit={handleSubmit}>
        <FormTextField type="hidden" name="customPropertyValues" value={'test'} sx={{ display: 'none' }} />
        {!initialValues?.typeId && (
          <Fragment>
            <Autocomplete
              onChange={(_, value) => {
                const mainComponentType = value as IMainComponentType
                setMainComponentTypeDetails({
                  mainComponentType: mainComponentType,
                  isCertificateDetailsRequired: mainComponentType?.componentType == Type.Net,
                })
                form.setValue('typeId', mainComponentType?.id)
              }}
              options={mainComponentTypes || []}
              value={mainComponentTypeDetails.mainComponentType}
              defaultValue={mainComponentTypes?.[0] as IMainComponentType}
              getOptionLabel={o => getTranslatedText(o.name)}
              renderOption={(props, o) => (
                <MenuItem key={o.id} value={o.id} {...props}>
                  {getTranslatedText(o.name)}
                </MenuItem>
              )}
              renderInput={params => (
                <AppTextField
                  {...params}
                  label={translate('main-component-type', { ns: 'main-component' })}
                  inputProps={{
                    ...params.inputProps,
                  }}
                />
              )}
              isOptionEqualToValue={(option, value) => option?.id === value?.id}
            />
          </Fragment>
        )}
        {mainComponentTypeDetails.mainComponentType && (
          <Fragment>
            <MainComponentStaticFormFields
              form={form}
              initialValues={initialValues}
              file={data}
              initialCertificateFiles={initialCertificateFiles}
              setInitialCertificateFiles={setInitialCertificateFiles}
              customerId={customerId as EntityId}
              isUpdateForm={isUpdateForm}
              mainComponentTypeDetails={mainComponentTypeDetails}
              setMainComponentTypeDetails={setMainComponentTypeDetails}
            />
            {customFields && customFields?.length > 0 && <Divider className="pt-4" />}
            <CustomMainComponentFields customFields={customFields as Array<ICustomProperty>} />
          </Fragment>
        )}
      </AppForm>
    </GenericDialogLayout>
  )
}

export default MainComponentForm
