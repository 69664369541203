import React, { createContext, useContext } from 'react'
import { useNavigate } from 'react-router-dom'

// Define the Navigation Context with appropriate types
const NavigationContext = createContext<{
  push: (path: string) => void
  navigateBack: () => void
  navigateTo: (path: string) => void
}>({
  push: () => {},
  navigateBack: () => {},
  navigateTo: () => {},
})

export const NavigationProvider = ({ children }: { children: React.ReactNode }) => {
  const navigate = useNavigate()

  // Helper function to retrieve history from sessionStorage
  const getHistory = (): string[] => {
    const storedHistory = sessionStorage.getItem('navigationHistory')
    return storedHistory ? JSON.parse(storedHistory) : []
  }

  // Helper function to save history to sessionStorage
  const saveHistory = (history: string[]) => {
    sessionStorage.setItem('navigationHistory', JSON.stringify(history))
  }

  const push = (path: string) => {
    const history = getHistory()
    history.push(path)
    saveHistory(history)
  }

  const pop = () => {
    const history = getHistory()
    history.pop()
    saveHistory(history)
  }

  const navigateBack = () => {
    const history = getHistory()
    pop()
    const lastPath = history[history.length - 1] // Get the new last path after popping
    if (lastPath) {
      navigate(lastPath) // Navigate to the new last path if it exists
    }
  }

  const navigateTo = (path: string) => {
    push(location.pathname)
    navigate(path)
  }

  return <NavigationContext.Provider value={{ push, navigateBack, navigateTo }}>{children}</NavigationContext.Provider>
}

// Custom hook to use the Navigation Context
export const useNavigation = () => {
  const context = useContext(NavigationContext)
  if (!context) {
    throw new Error('useNavigation must be used within a NavigationProvider')
  }
  return context
}
