import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import EN_TRANSLATIONS from './translations/en/base.json'
import NO_TRANSLATIONS from './translations/no/base.json'

import EN_FORMS from './translations/en/form.en.json'
import NO_FORMS from './translations/no/form.no.json'

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
export const defaultNS = 'base'

export const i18_resources = {
  en: {
    ...EN_TRANSLATIONS,
    forms: EN_FORMS,
  },
  nb: {
    ...NO_TRANSLATIONS,
    forms: NO_FORMS,
  },
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: i18_resources,
    // ns: ['base', 'form'],
    // defaultNS,
    fallbackLng: 'en', // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    lng: localStorage.getItem('languageCode') || 'en',
  })
export default i18n
