import { ShowDialogProps } from '../../show-dialog'
import { SendToLocalityDialogForm } from './SendToLocalityDialogForm'
import { useGetMainComponentById } from '../../api/main-component'
import { IMainComponentType as Type } from '../../enums'
import { useEffect, useState } from 'react'
import { IMainComponentType } from 'src/generated-types/main-component-type'
import { CenteredSpinner } from 'src/components/Spinner'

interface SendToLocalityDialogProps extends ShowDialogProps {
  customerId: EntityId
  mainComponentId: EntityId
  onSubmit: (...args: any[]) => any
}

export const SendToLocalityDialog: React.FC<SendToLocalityDialogProps> = ({
  pop,
  customerId,
  mainComponentId,
  onSubmit,
}) => {
  const { data: mainComponent, isLoading } = useGetMainComponentById(customerId, mainComponentId)
  const isCertificateAddFormVisible = mainComponent?.isValidCertificateAvailable ?? false
  const isCertificateRequired = mainComponent?.type?.componentType === Type.Net ? true : false
  const isPlacementVisible =
    mainComponent?.type.componentType == Type.Net ||
    mainComponent?.type.componentType == Type.Ring ||
    mainComponent?.type.componentType == Type.LiceSkirt

  const [mainComponentTypeDetails, setMainComponentTypeDetails] = useState<{
    mainComponentType: IMainComponentType | undefined
    isCertificateDetailsRequired: boolean
  }>({
    mainComponentType: mainComponent?.type,
    isCertificateDetailsRequired: mainComponent?.type?.componentType === Type.Net,
  })

  useEffect(() => {
    setMainComponentTypeDetails({
      mainComponentType: mainComponent?.type,
      isCertificateDetailsRequired: isCertificateRequired,
    })
  }, [mainComponent])

  if (isLoading) return <CenteredSpinner containerProps={{ height: 600, width: 400 }} />

  return (
    <SendToLocalityDialogForm
      onSubmit={form => {
        {
          form.mainComponentTypeId = mainComponent?.typeId
          form.mainComponentId = mainComponent?.id
          const {
            certificateNumber,
            certificateDate,
            validUntil,
            certificateDocumentType,
            certificateFileId,
            certificateSupplierId,
            ...rest
          } = form
          if (mainComponentTypeDetails?.isCertificateDetailsRequired) {
            rest.certificate = {
              certificateNumber,
              certificateDate,
              validUntil,
              certificateDocumentType,
              fileId: certificateFileId,
              supplierId: certificateSupplierId,
              mainComponentId: mainComponent?.id,
            }
          }
          onSubmit(rest, pop)
        }
      }}
      onCancel={pop}
      customerId={customerId}
      isCertificateAddFormVisible={isCertificateAddFormVisible}
      isPlacementVisible={isPlacementVisible}
      mainComponentTypeDetails={mainComponentTypeDetails}
      setMainComponentTypeDetails={setMainComponentTypeDetails}
    />
  )
}
