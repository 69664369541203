import { Grid, Box, InputBase, Stack, Typography, IconButton, FormHelperText, styled } from '@mui/material'
import { useState, useEffect, useMemo, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { MdClose, MdArrowBack, MdArrowForward } from 'react-icons/md'
import InfiniteScroll from 'react-infinite-scroll-component'
import { z } from 'zod'
import { useGetPositionDrawings } from '../../../../api/position-drawing'
import { AppFormProps, AppForm, FormCheckbox } from '../../../../components/FormCore'
import { GenericDialogLayout } from '../../../../components/GenericDialogLayout'
import { MainButton } from '../../../../components/MainButton'
import { CenteredSpinner } from '../../../../components/Spinner'
import { IPositionType } from '../../../../generated-types'
import { IPositionDrawing } from '../../../../generated-types/position-drawing'
import { useZodForm } from '../../../../hooks/zod-form'

export const positionDrawingSchema = z.object({
  positionDrawing: z.any(),
  replaceComponents: z.boolean().default(false),
})

interface SelectPositionDrawingFormProps extends AppFormProps {
  positionDrawingId: EntityId
  positionType: IPositionType
  setSelectedPositionDrawing?: React.Dispatch<React.SetStateAction<IPositionDrawing | undefined>>
  isUpdateForm: boolean | undefined
  customerId: EntityId
  mooringId: EntityId
}

export const SelectPositionDrawingForm: React.FC<SelectPositionDrawingFormProps> = ({
  onSubmit,
  onCancel,
  positionDrawingId,
  positionType,
  setSelectedPositionDrawing,
  isUpdateForm,
  customerId,
  mooringId,
}) => {
  const { t } = useTranslation(['position', 'common'])
  const [searchString, setSearchString] = useState('')
  const [numberOfElements, setNumberOfElements] = useState(1)

  const { data, isLoading, refetch } = useGetPositionDrawings(
    searchString,
    0,
    8 * numberOfElements,
    positionType,
    mooringId,
    customerId,
  )

  const form = useZodForm(positionDrawingSchema)
  const [selectedDrawing, setSelectedDrawing] = useState<IPositionDrawing | undefined>(undefined)

  useEffect(() => {
    setSelectedDrawing(data?.items?.find(d => d.id == positionDrawingId))
  }, [data?.items, positionDrawingId])

  useEffect(() => {
    form.setValue('positionDrawing', selectedDrawing)
  }, [form, selectedDrawing])

  useEffect(() => {
    document
      .getElementById(`drawing-${selectedDrawing?.id ?? positionDrawingId}`)
      ?.scrollIntoView({ behavior: 'smooth', block: 'center' })
  }, [data?.items, positionDrawingId, selectedDrawing])

  useEffect(() => {
    refetch()
  }, [searchString, numberOfElements, refetch])

  useEffect(() => {
    setSelectedPositionDrawing && setSelectedPositionDrawing(selectedDrawing)
  }, [selectedDrawing])

  const positionDrawings: Array<IPositionDrawing> = useMemo(() => {
    return Array.from(data?.items ?? [])
  }, [data?.items])

  const currentIndex = useMemo(() => {
    return positionDrawings.findIndex(x => x.id == selectedDrawing?.id) == -1
      ? 0
      : positionDrawings.findIndex(x => x.id == selectedDrawing?.id)
  }, [positionDrawings, selectedDrawing?.id])

  const handleRemoveDrawing = useCallback(() => {
    setSelectedDrawing(undefined)
  }, [])

  const handleBack = useCallback(() => {
    if (currentIndex > 0) {
      const newPositionDrawing = positionDrawings.find((_, i) => i == currentIndex - 1)
      setSelectedDrawing(newPositionDrawing)
    }
  }, [currentIndex, positionDrawings])

  const handleForward = useCallback(() => {
    if (currentIndex < positionDrawings.length - 1) {
      if (selectedDrawing?.id == undefined) {
        setSelectedDrawing(positionDrawings[0])
      } else {
        const newPositionDrawing = positionDrawings.find((_, i) => i == currentIndex + 1)
        setSelectedDrawing(newPositionDrawing)
      }
    }
  }, [currentIndex, positionDrawings, selectedDrawing])

  if (isLoading) <CenteredSpinner />

  return (
    <GenericDialogLayout
      title={
        positionDrawingId
          ? t('updatePositionDrawing', { ns: 'position' })
          : t('selectPositionDrawing', { ns: 'position' })
      }
      pop={onCancel}
      removedCloseButton={false}
      actions={[
        <MainButton
          disabled={positionDrawingId == selectedDrawing?.id}
          key="add-position-drawing"
          onClick={() => {
            form.handleSubmit(onSubmit)()
          }}
          loading={form.formState.isSubmitting}
        >
          {positionDrawingId ? t('update', { ns: 'common' }) : t('add', { ns: 'common' })}
        </MainButton>,
      ]}
    >
      <AppForm form={form} onSubmit={onSubmit}>
        <Grid container p={1}>
          <Grid item xs={4} height={500}>
            <Box p={1}>
              <InputBase
                sx={{ borderColor: 'white' }}
                autoFocus={false}
                placeholder={t('search', { ns: 'common' }) + '...'}
                inputProps={{ 'aria-label': 'search' }}
                defaultValue={''}
                onChange={event => {
                  setNumberOfElements(1)
                  setSearchString(event.target.value)
                }}
              />
              <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={0.5}>
                <Typography variant="body1">{t('position-drawing', { ns: 'position' })}:</Typography>
                <Typography variant="body1" fontWeight={'bold'}>
                  {selectedDrawing?.drawingNumber ?? t('n/a', { ns: 'common' })}
                </Typography>
                {selectedDrawing?.drawingNumber != undefined ? (
                  <IconButton
                    sx={{ ':hover': { backgroundColor: 'red' }, backgroundColor: '#00526B' }}
                    onClick={handleRemoveDrawing}
                    aria-label="back"
                  >
                    <MdClose size={15} />
                  </IconButton>
                ) : (
                  <></>
                )}
              </Stack>
              {form.formState.errors.positionDrawing && (
                <FormHelperText error={true} sx={{ textAlign: 'left' }}>
                  {form.formState.errors.positionDrawing.message}
                </FormHelperText>
              )}
            </Box>
            <ImgList
              scrollThreshold={0.9}
              dataLength={data?.items.length ?? 0}
              height={500}
              next={() => {
                if ((data && data?.items.length < data?.totalCount) ?? false) setNumberOfElements(numberOfElements + 1)
              }}
              hasMore={(data && data?.items.length < data?.totalCount) ?? false}
              loader={<CenteredSpinner />}
            >
              {positionDrawings.map(positionDrawing => (
                <ImgContainer
                  key={positionDrawing.id}
                  id={'drawing-' + positionDrawing.id}
                  onClick={() => {
                    setSelectedDrawing(positionDrawing)
                  }}
                >
                  <ImgBox
                    className={'rounded-lg'}
                    height={100}
                    sx={{
                      border: positionDrawing.id == selectedDrawing?.id ? '5px solid green' : undefined,
                      backgroundImage: `url(${positionDrawing.fileUrl})`,
                    }}
                  />
                  <Typography variant="caption">{positionDrawing.drawingNumber}</Typography>
                </ImgContainer>
              ))}
            </ImgList>
          </Grid>
          <Grid item xs={8} p={1}>
            <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
              <IconButton disabled={currentIndex === 0} onClick={handleBack} aria-label="back">
                <MdArrowBack />
              </IconButton>
              <ImgBox
                className={'rounded-lg'}
                sx={{
                  backgroundImage: `url(${selectedDrawing?.fileUrl})`,
                  backgroundColor: '#EBF9FC',
                  height: 500,
                }}
              />
              <IconButton
                disabled={currentIndex === positionDrawings.length - 1}
                onClick={handleForward}
                aria-label="forward"
              >
                <MdArrowForward />
              </IconButton>
            </Stack>
            <Box p={1}>
              <FormCheckbox name="replaceComponents" label={t('replace-component', { ns: 'position' })} />
            </Box>
          </Grid>
        </Grid>
      </AppForm>
    </GenericDialogLayout>
  )
}

const ImgBox = styled(Box)({
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  width: '100%',
})

const ImgContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: '50%',
  padding: 5,
})

const ImgList = styled(InfiniteScroll)({
  display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    alignContent: 'flex-start',
    width: '100%',
    height: 500,
    padding: 5,
})
