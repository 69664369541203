import { Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { invalidatePositionDrawingCache, useAddPositionDrawing } from '../../../api/position-drawing'
import { ShowDialogProps } from '../../../show-dialog'
import { PositionDrawingTable } from './positionDrawingTable'
import { PositionDrawingForm } from './positionDrawingForm'
import { ScreenTitleBar } from '../../localities/components/ScreenTitleBar'
import { useCallback, useEffect } from 'react'
import { ALREADY_EXISTS } from '../../../constant'
import { toast } from 'react-toastify'
import { IPositionDrawingProductAdd } from 'src/generated-types/position-drawing-product-add'
import { useDialogCtx } from 'src/hooks/context-hooks'

const CreatePositionDrawingDialog: React.FC<ShowDialogProps> = ({ pop }) => {
  const { t } = useTranslation(['admin-position-drawing', 'common'])

  const mutation = useAddPositionDrawing()

  useEffect(() => {
    if (mutation.error != null) {
      if (mutation.error['data'] == ALREADY_EXISTS) {
        toast(t('already-exists'), { type: 'error' })
      }
    }
  }, [mutation.error])

  const onSubmit = async form => {
    if (
      form.positionDrawingProducts == null ||
      form.positionDrawingProducts.length == 0 ||
      !!(form.positionDrawingProducts as IPositionDrawingProductAdd[])?.find(x => x.quantity == undefined) ||
      !!(form.positionDrawingProducts as IPositionDrawingProductAdd[])?.find(x => x.drawingPositionNumber == undefined)
    ) {
      toast(t('drawingPositionProductValidationError', { ns: 'admin-position-drawing' }), { type: 'error' })
      return
    }
    await mutation.mutateAsync(form).then(() => {
      invalidatePositionDrawingCache.useGetPositionDrawings()
      pop(true)
    })
  }

  return <PositionDrawingForm title={t('addPositionDrawing')} onSubmit={onSubmit} onCancel={() => pop()} />
}

export const PositionDrawingScreen = () => {
  const { t } = useTranslation(['admin-position-drawing', 'common'])
  const { showDialog } = useDialogCtx()

  const onAdd = useCallback(async () => {
    const added = await showDialog(
      CreatePositionDrawingDialog,
      {
        opts: {
          maxWidth: 'md',
          fullWidth: true,
        },
      },
      undefined,
      true,
    )
    if (!added) return
  }, [])

  return (
    <>
      <Box className="p-3 mb-2 bg-white shadow-md" borderRadius={2}>
        <div id="positionDrawingDialog"></div>
        <ScreenTitleBar
          title={t('positionDrawing')}
          addNewItemText={t('add', { ns: 'common' })}
          onAddNewItemClick={onAdd}
        />
        <PositionDrawingTable />
      </Box>
    </>
  )
}
