import { Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useGetCustomerUsers } from '../../../../api/customer'
import { FormTextField, FormSelectField, FormFileCoreUpload } from '../../../../components/FormCore'
import { ILocality } from '../../../../generated-types'
import { getUserDetail, UserDetail } from '../../../../utils/getUserDetails'
import { useGetFormSchema } from '../../../../api/form-schema'
import { useGetLocalities } from '../../../../api/localities'
import { IFormSchema } from '../../../../generated-types/form-schema'
import { APIResultCount } from 'src/constant'
import { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { TaskTypes } from 'src/enums'
import { taskIntervalList } from './task-constants'
import BasicDatePicker from 'src/components/DateTimePicker'
import { Flex } from 'src/styles/flexComponent'

interface InformationFormProps {
  customerId: any
  localityId?: EntityId
  formType: TaskTypes
  isDurationDisabled: boolean
  setIisDurationDisabled: React.Dispatch<React.SetStateAction<boolean>>
}

const InformationForm: React.FC<InformationFormProps> = ({
  customerId,
  localityId,
  formType,
  isDurationDisabled,
  setIisDurationDisabled,
}) => {
  const { t: translate } = useTranslation(['locality', 'common'])
  const { setValue, setFocus } = useFormContext()

  const { data: customerUsers } = useGetCustomerUsers(customerId as EntityId)
  const { data: localities } = useGetLocalities(customerId as EntityId, {
    page: 0,
    pageSize: APIResultCount.Max,
  })
  const { data: formSchemas } = useGetFormSchema(customerId as EntityId)

  const currentLocality = localities?.items?.find(l => l.id == localityId)

  useEffect(() => {
    if (currentLocality) {
      setValue('localityIds', currentLocality ? [{ ...currentLocality }] : [])
    }
  }, [currentLocality])

  return (
    <>
      <FormTextField name="title" label={translate('title', { ns: 'common' })} />
      <Flex.Row>
        <Flex.Column width={'50%'}>
          <BasicDatePicker name="startDate" label={translate('startDate', { ns: 'common' })} />
        </Flex.Column>
        <Flex.Column width={'50%'}>
          {formType == TaskTypes.Repeatable && (
            <BasicDatePicker name="endDate" label={translate('endDate', { ns: 'common' })} />
          )}
        </Flex.Column>
      </Flex.Row>
      <Flex.Row>
        {formType == TaskTypes.Repeatable && (
          <Flex.Column width={'50%'}>
            <FormSelectField
              fullWidth
              name="interval"
              data={taskIntervalList}
              disablePortal={false}
              label={translate('interval', { ns: 'task' })}
              getOptionLabel={option => option.option}
              renderOption={(props: any, option) => (
                <Box {...props} key={option.value}>
                  {option.option}
                </Box>
              )}
              clearOnBlur={false}
              clearOnEscape={false}
              isOptionEqualToValue={(o, v) => o.value === v.value}
              onChange={(eve, value) => {
                setValue('interval', value)
                if (value == taskIntervalList[0]) {
                  setIisDurationDisabled(true)
                  setValue('duration', '1')
                  setFocus('duration')
                } else {
                  setIisDurationDisabled(false)
                }
              }}
            />
          </Flex.Column>
        )}
        <Flex.Column width={'50%'}>
          <FormTextField
            type="number"
            name="duration"
            label={translate('duration', { ns: 'task' })}
            InputProps={{
              readOnly: isDurationDisabled,
            }}
            focused={isDurationDisabled}
          />
        </Flex.Column>
      </Flex.Row>
      <FormSelectField
        name="localityIds"
        data={localities?.items || []}
        label={translate('localities', { ns: 'common' })}
        getOptionLabel={(option: ILocality) => option.name}
        renderOption={(props: any, option) => (
          <Box {...props} key={option.id}>
            {option.name}
          </Box>
        )}
        multiple
        defaultValue={currentLocality ? [{ ...currentLocality }] : []}
        clearOnBlur={false}
        clearOnEscape={false}
        isOptionEqualToValue={(o, v) => o.id === v.id}
        disabled={!!localityId}
      />
      <FormSelectField
        name="responsiblePerson"
        data={customerUsers!}
        label={translate('responsibleUser', { ns: 'locality' })}
        getOptionLabel={option => getUserDetail(option, UserDetail.NameAndEmail)}
        renderOption={(props: any, option) => (
          <Box {...props} key={option.id}>
            {getUserDetail(option, UserDetail.NameAndEmail)}
          </Box>
        )}
        clearOnBlur={false}
        clearOnEscape={false}
        isOptionEqualToValue={(o, v) => o.id === v.id}
      />
      <FormTextField name="description" label={translate('description', { ns: 'common' })} multiline rows={4} />
      <FormSelectField
        name="formSchemaIds"
        data={formSchemas || []}
        defaultValue={[]}
        label={translate('reports', { ns: 'common' })}
        getOptionLabel={(option: IFormSchema) => option.name}
        renderOption={(props: any, option) => (
          <Box {...props} key={option.id}>
            {option.name}
          </Box>
        )}
        multiple
        clearOnBlur={false}
        clearOnEscape={false}
        isOptionEqualToValue={(o, v) => o.id === v.id}
      />
      <FormFileCoreUpload
        label={translate('fileName', { ns: 'common' })}
        name="fileId"
        mapFileKey="id"
        height="150px"
      />
    </>
  )
}

export default InformationForm
