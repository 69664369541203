import { Alert, AlertProps, Box, Button, IconButton, Snackbar } from '@mui/material'
import {
  DataGridPro,
  GridApi,
  GridApiRef,
  GridColumns,
  GridRenderCellParams,
  GridRowId,
  GridRowParams,
  GridToolbarContainer,
  MuiBaseEvent,
  MuiEvent,
  useGridApiRef,
} from '@mui/x-data-grid-pro'
import { FC, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MdAdd, MdCancel, MdDelete, MdEdit, MdSave } from 'react-icons/md'
import { invalidatePositionDrawingCache, useDeletePositionDrawingProduct } from '../../../../api/position-drawing'
import { useGetProducts } from '../../../../api/product'
import { SelectField } from '../../../../components/SelectField'
import { CenteredSpinner } from '../../../../components/Spinner'
import { IProduct } from '../../../../generated-types'
import { APIResultCount } from '../../../../constant'
import { AlertDialog } from '../../../../show-dialog'
import { useFormContext } from 'react-hook-form'
import { toast } from 'react-toastify'
import { IPositionDrawingProductAdd } from 'src/generated-types/position-drawing-product-add'
import { useDialogCtx } from 'src/hooks/context-hooks'

interface RowMenuProps {
  api: GridApi
  id: GridRowId
}

function ProductEditInputCell(props: GridRenderCellParams) {
  const { id, api, field } = props
  const row = api.getRow(id)

  const { data, isLoading } = useGetProducts('', 0, APIResultCount.Max)
  if (isLoading) return <CenteredSpinner />

  const selectedProduct = row != null && row.productId ? data?.items.find(p => p.id === row.productId) : null

  const handleChange = (event, product) => {
    api.setEditCellValue({ id, field, value: product.number }, event)
    if (row != null) {
      row.productId = product.id
      row.description = product.description
    }
  }

  return (
    <SelectField
      style={{ width: 240 }}
      data={data?.items as IProduct[]}
      getOptionLabel={o => o.number}
      onChange={handleChange}
      renderOption={(props: any, o) => (
        <Box {...props} key={o.id}>
          {o.number}
        </Box>
      )}
      defaultValue={selectedProduct}
      isOptionEqualToValue={(o, v) => o.id === v.id}
    />
  )
}

function renderProductEditInputCell(params) {
  return <ProductEditInputCell {...params} />
}

function RowMenuCell(props: RowMenuProps) {
  const { showDialog } = useDialogCtx()
  const { api, id } = props
  const isInEditMode = api.getRowMode(id) === 'edit'
  const [snackbar, setSnackbar] = useState<Pick<AlertProps, 'children' | 'severity'> | null>(null)
  const { t } = useTranslation(['admin-position-drawing', 'common'])
  const { getValues, reset } = useFormContext()

  const handleEditClick = event => {
    event.preventDefault()
    event.stopPropagation()
    api.setRowMode(id, 'edit')
  }

  const handleSaveClick = event => {
    event.stopPropagation()
    let hasValidationError = false
    api.commitRowChange(id)
    const uncommitedRow = api.getRow(id)

    if (!uncommitedRow || !uncommitedRow.productId) return

    if (uncommitedRow.quantity == null || uncommitedRow.quantity < 1) {
      toast(t('productQuantityValidationError'), { type: 'error' })
      hasValidationError = true
      return
    }
    if (uncommitedRow.drawingPositionNumber == null || uncommitedRow.drawingPositionNumber < 1) {
      toast(t('drawingPositionNumberValidationError'), { type: 'error' })
      hasValidationError = true
      return
    }

    api.getRowModels().forEach(r => {
      if (r.drawingPositionNumber === uncommitedRow?.drawingPositionNumber && r.id !== uncommitedRow?.id) {
        toast(t('duplicateDrawingNumber'), { type: 'error' })
        hasValidationError = true
        return
      }
    })

    if (!hasValidationError) {
      api.commitRowChange(id)
      api.setRowMode(id, 'view')
      api.updateRows([{ ...uncommitedRow, isNew: false }])
    }
  }

  const mutation = useDeletePositionDrawingProduct()

  const handleDeleteClick = useCallback(
    async (id: EntityId) => {
      const rowData = api.getRow(id)
      const rowIndex = api.getRowIndexRelativeToVisibleRows(id)
      showDialog(AlertDialog, {
        componentProps: {
          title: t('deletePositionDrawingProduct'),
          text: t('areYouSureDeletePositionDrawingProduct'),
          onConfirm: async pop => {
            if (rowData?.isNew == null) {
              await mutation.mutateAsync(id)
              invalidatePositionDrawingCache.useGetPositionDrawings()
              invalidatePositionDrawingCache.getPositionDrawingById(rowData.positionDrawingId)
            } else {
              const allValues = getValues()
              let positionDrawingProducts = allValues.positionDrawingProducts as IPositionDrawingProductAdd[]
              const updatedProducts = [...positionDrawingProducts]
              updatedProducts.splice(rowIndex, 1)
              positionDrawingProducts = [...updatedProducts]
              reset({
                ...allValues,
                positionDrawingProducts: positionDrawingProducts.sort(),
              })
            }

            pop(true)
          },
        },
      })
    },
    [api, getValues, mutation, reset, t],
  )

  const handleCancelClick = event => {
    event.stopPropagation()
    api.setRowMode(id, 'view')

    const row = api.getRow(id)
    if (row!.isNew) {
      api.updateRows([{ id, _action: 'delete' }])
    }
  }

  if (isInEditMode) {
    return (
      <>
        <IconButton color="primary" size="small" aria-label="save" onClick={handleSaveClick}>
          <MdSave fontSize="small" />
        </IconButton>
        <IconButton color="inherit" size="small" aria-label="cancel" onClick={handleCancelClick}>
          <MdCancel fontSize="small" />
        </IconButton>
      </>
    )
  }

  const handleCloseSnackbar = () => setSnackbar(null)
  return (
    <>
      <IconButton color="inherit" size="small" aria-label="edit" onClick={handleEditClick}>
        <MdEdit fontSize="small" />
      </IconButton>
      <IconButton color="inherit" size="small" aria-label="delete" onClick={() => handleDeleteClick(id)}>
        <MdDelete fontSize="small" />
      </IconButton>
      {!!snackbar && (
        <Snackbar
          open
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          onClose={handleCloseSnackbar}
          autoHideDuration={6000}
        >
          <Alert {...snackbar} onClose={handleCloseSnackbar} />
        </Snackbar>
      )}
    </>
  )
}

interface EditToolbarProps {
  apiRef: GridApiRef
}

function EditToolbar(props: EditToolbarProps) {
  const { apiRef } = props
  const { t } = useTranslation(['admin-position-drawing', 'common'])

  const handleClick = () => {
    const id = Math.floor(Math.random() * 100) //randomId()
    const allRows = apiRef.current.getSortedRows()
    apiRef.current.setRows(allRows.filter(x => !x.isNew))
    apiRef.current.updateRows([{ id, isNew: true }])
    apiRef.current.setRowMode(id, 'edit')
    // Wait for the grid to render with the new row
    setTimeout(() => {
      apiRef.current.scrollToIndexes({
        rowIndex: apiRef.current.getRowsCount() - 1,
      })
      apiRef.current.setCellFocus(id, 'productNumber')
    }, 150)
  }

  return (
    <GridToolbarContainer>
      <Button color="primary" startIcon={<MdAdd />} onClick={handleClick}>
        {t('addAProduct')}
      </Button>
    </GridToolbarContainer>
  )
}

export const ProductGrid: FC = () => {
  const { getValues, setValue } = useFormContext()
  const apiRef = useGridApiRef()
  const { t } = useTranslation(['admin-position-drawing', 'common'])

  const columns: GridColumns = [
    { field: 'productId', headerName: 'ProductId', type: 'number', hide: true },
    {
      field: 'productNumber',
      headerName: t('product', { ns: 'common' }),
      type: 'string',
      renderEditCell: renderProductEditInputCell,
      minWidth: 250,
      editable: true,
    },
    { field: 'quantity', headerName: t('quantity', { ns: 'common' }), type: 'number', width: 200, editable: true },
    {
      field: 'drawingPositionNumber',
      headerName: t('drawingPositionNumber'),
      type: 'number',
      width: 200,
      editable: true,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      renderCell: RowMenuCell,
      sortable: false,
      width: 190,
      headerAlign: 'center',
      filterable: false,
      align: 'center',
      disableColumnMenu: true,
      disableReorder: true,
    },
  ]

  const handleChange = () => {
    const selectedPositionDrawingProducts = [] as IPositionDrawingProductAdd[]
    apiRef.current.getRowModels().forEach(row => {
      selectedPositionDrawingProducts.push({
        id: row.id,
        productId: row.productId,
        productNumber: row.productNumber,
        quantity: row.quantity,
        drawingPositionNumber: row.drawingPositionNumber,
        isNew: true,
      } as IPositionDrawingProductAdd)
    })
    setValue('positionDrawingProducts', selectedPositionDrawingProducts)
  }

  const handleRowEditStart = (params: GridRowParams, event: MuiEvent<React.SyntheticEvent>) => {
    event.defaultMuiPrevented = true
  }

  const handleRowEditStop = (params: GridRowParams, event: MuiEvent<MuiBaseEvent>) => {
    event.defaultMuiPrevented = true
  }

  return (
    <Box height={'300px'} px={1}>
      <DataGridPro
        rows={getValues('positionDrawingProducts') ?? []}
        columns={columns}
        apiRef={apiRef}
        editMode="row"
        onRowEditStart={handleRowEditStart}
        onRowEditStop={handleRowEditStop}
        onEditRowsModelChange={() => handleChange()}
        components={{
          Toolbar: EditToolbar,
        }}
        componentsProps={{
          toolbar: { apiRef },
        }}
        initialState={{
          sorting: {
            sortModel: [
              {
                field: 'drawingPositionNumber',
                sort: 'asc',
              },
            ],
          },
        }}
      />
    </Box>
  )
}
