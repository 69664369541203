import {
  Box,
  Checkbox,
  CheckboxProps,
  CircularProgress,
  FormControlLabel,
  styled,
  TextFieldProps,
  Typography,
  useTheme,
} from '@mui/material'
import React, { ReactElement, ReactNode, useEffect, useRef, useState } from 'react'
import { UseFormReturn, FormProvider, useFormState, useFormContext, useController, FieldValues } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { AppFormProvider } from '../context/AppFormContext'
import { IBaseEntity, IFile } from '../generated-types'
import { useAppFormCtx } from '../hooks/context-hooks'
import { AppTextField } from './AppTextField'
import { FileUploader, FileUploaderProps } from './FileUploader'
import { MainButton, MainButtonProps } from './MainButton'
import { SelectField, SelectFieldProps } from './SelectField'
import { useUploadFile } from '../api/file'
import { CommonFileUploader, CommonFileUploaderProps } from './common/input/CommonFileUploader'

export interface AppFormProps<T = any> {
  onSubmit: (form: any) => any
  onCancel?: () => any
  initialValues?: T
}
export type AppFormComponent<T = any, ExtraProps = any> = React.FC<AppFormProps<T> & ExtraProps>

interface CommonFormFieldAttributes {
  name: string
  label?: string
  defaultValue?: any
  styledLabelComponent?: ReactElement
}

type FormField<T = any> = React.FC<CommonFormFieldAttributes & T>

interface Props<T extends FieldValues = any> {
  children: any
  form: UseFormReturn<T>
  hasInitialValues?: boolean
  onSubmit?: (form: any) => any
}

export const AppForm = <T extends FieldValues>({
  form,
  onSubmit,
  children,
  hasInitialValues = false,
  ...formProps
}: Props<T> &
  Omit<React.DetailedHTMLProps<React.FormHTMLAttributes<HTMLFormElement>, HTMLFormElement>, 'onSubmit'>) => {
  useEffect(() => {
    // @ts-ignore
    window.form = form

    if (getLogLevel() <= logLevels.INFO) {
      form.watch(v => Logger.info(v))
      Logger.info(form.formState.errors)
    }
  }, [])

  return (
    <FormProvider {...form}>
      <AppFormProvider hasInitialValues={hasInitialValues}>
        <form {...formProps} noValidate onSubmit={onSubmit && form.handleSubmit(onSubmit as any)}>
          {children}
        </form>
      </AppFormProvider>
    </FormProvider>
  )
}

export function useFormFieldError(name: string) {
  const { errors } = useFormState()
  const { t } = useTranslation('forms')

  const error = errors[name]

  return {
    hasError: !!error,
    message: error ? t(error.message as string) : undefined,
  }
}

interface FormTextFieldProps {
  icon?: ReactNode
}

export const FormTextField: FormField<TextFieldProps & FormTextFieldProps> = ({
  name,
  children,
  fullWidth = true,
  icon,
  InputProps,
  ...rest
}) => {
  const { register } = useFormContext()
  const { hasError, message } = useFormFieldError(name)

  const { ref, ...registerRest } = register(name)

  return (
    // @ts-ignore
    <AppTextField
      {...rest}
      inputRef={ref}
      {...registerRest}
      name={name}
      helperText={message}
      error={hasError}
      InputProps={InputProps}
      fullWidth={fullWidth}
    >
      {children}
    </AppTextField>
  )
}

type FormSelectFieldProps<T> = CommonFormFieldAttributes & SelectFieldProps<T>

export const FormSelectField = <T,>({
  name,
  defaultValue = null,
  onChange,
  multiple,
  value,
  variant,
  ...rest
}: FormSelectFieldProps<T>) => {
  const { hasError, message } = useFormFieldError(name)

  const { field } = useController({ name, defaultValue })
  const handleChange = (_, val) => {
    if (multiple) {
      field.onChange([...val])
    } else {
      field.onChange(val)
    }
  }
  return (
    <SelectField
      {...rest}
      multiple={multiple}
      error={hasError}
      helperText={message}
      onChange={onChange ?? handleChange}
      value={value ?? field.value}
      variant={variant}
    />
  )
}

interface FormSubmitButtonProps {
  createText?: string
  updateText?: string
  isLoading?: boolean
}
export const FormSubmitButton: React.FC<
  FormSubmitButtonProps & Omit<MainButtonProps, 'loading' | 'type' | 'onClick'>
> = ({ createText, isLoading, updateText, ...btnProps }) => {
  const { hasInitialValues } = useAppFormCtx()
  const { isSubmitting } = useFormState()
  const { t } = useTranslation(['common'])

  return (
    <MainButton {...btnProps} loading={isSubmitting} type="submit">
      <>
        {isLoading && <CircularProgress style={{ height: 20, width: 20 }} />}
        {!hasInitialValues ? createText ?? t('create') : updateText ?? t('update')}
      </>
    </MainButton>
  )
}

export const FormCheckbox: React.FC<CommonFormFieldAttributes & Omit<CheckboxProps, 'defaultValue'>> = ({
  name,
  label,
  defaultValue,
  styledLabelComponent,
  ...checkboxProps
}) => {
  const theme = useTheme()
  const {
    field: { onChange, value },
  } = useController({ name, defaultValue })

  return (
    <FormControlLabel
      control={
        <Checkbox
          sx={{ color: theme.palette.paleBlue.main }}
          onChange={(_, checked) => onChange(checked)}
          checked={value ?? false}
          {...checkboxProps}
        />
      }
      label={!styledLabelComponent? label! : styledLabelComponent}
      sx={{ paddingX: 1}}
    />
  )
}

interface FormFileUploadProps {
  mapFileKey?: keyof IFile | keyof IBaseEntity | null
  getUploadedFiles?: (files: IFile | IFile[]) => void
  isDeletable?: boolean
}

export const FormFileUpload: FormField<FormFileUploadProps & Omit<FileUploaderProps, 'onChange'>> = ({
  name,
  mapFileKey,
  getUploadedFiles: getUploadedFiles,
  ...rest
}) => {
  const { field } = useController({ name })

  const onChange = (file: IFile | IBaseEntity) => {
    if (!file) return field.onChange(null)

    field.onChange(!mapFileKey ? file : file[mapFileKey])
  }

  // @ts-ignore
  return <FileUploader onChange={onChange} {...rest} name={name} getUploadedFiles={getUploadedFiles} />
}

export const FormFileCoreUpload: FormField<FormFileUploadProps & Omit<CommonFileUploaderProps, 'onChange'>> = ({
  name,
  mapFileKey,
  getUploadedFiles: getUploadedFiles,
  isDeletable,
  multiple,
  ...rest
}) => {
  const { field } = useController({ name })

  const onChange = (file: IFile | IBaseEntity | IFile[]) => {
    if (!file) return field.onChange(null)
    if (multiple) {
      field.onChange(!mapFileKey ? file : (file as IFile[]).map(f => f[mapFileKey]))
    } else {
      field.onChange(!mapFileKey ? file : file[mapFileKey])
    }
  }

  // @ts-ignore
  return (
    <CommonFileUploader
      onChange={onChange}
      multiple={multiple}
      {...rest}
      isDeletable={isDeletable}
      name={name}
      getUploadedFiles={getUploadedFiles}
    />
  )
}

const ImageContainer = styled(Box)({
  height: 100,
  width: 120,
  backgroundSize: 'cover',
  borderRadius: 10,
  '&:hover': {
    opacity: 0.8,
    scale: 1.1,
  },
})

export const FormImageFileUpload: FormField<FormFileUploadProps & Omit<FileUploaderProps, 'onChange'>> = ({
  name,
  mapFileKey,
  label,
  initialFiles,
  getUploadedFiles,
  ...rest
}) => {
  const { field } = useController({ name })
  const inputRef = useRef<HTMLInputElement>(null)
  const mutation = useUploadFile(true)
  const [file, setFile] = useState(initialFiles as string)
  const { t } = useTranslation('common')

  const handleClick = () => {
    if (inputRef) {
      const button = inputRef as any
      button.current.click()
    }
  }

  const onChange = evt => {
    if (evt.target.files != null) {
      const file = evt.target.files[0]
      if (!file) return field.onChange(null)
      const formData = new FormData()
      formData.append('file', file)
      mutation.mutateAsync(formData).then(result => {
        const file = result.data as IFile
        field.onChange(file.id)
        setFile(file.url)
        getUploadedFiles && getUploadedFiles(file)
      })
    }
  }

  // @ts-ignore
  return (
    <Box width={150}>
      <input style={{ display: 'none' }} ref={inputRef} type="file" onChange={onChange} accept="image/*" />
      <Typography width={'100%'}>{label}</Typography>
      <Box
        className="cursor-pointer"
        height={100}
        width={120}
        onClick={() => {
          handleClick()
        }}
      >
        {file ? (
          <ImageContainer
            sx={{ backgroundImage: `url(${file})` }}
          />
        ) : (
          <ImageContainer
            display={'flex'}
            flexDirection={'column'}
            justifyContent={'center'}
            height={'100%'}
            border={'1px solid gray'}
          >
            <Typography align="center" fontSize={15} color={'gray'}>
              {t('choose-image')}
            </Typography>
          </ImageContainer>
        )}
      </Box>
    </Box>
  )
}
