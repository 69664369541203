import { LatLng } from 'leaflet'
import React, { useEffect } from 'react'
import { CircleMarker, MapContainer, Pane, TileLayer, Tooltip, useMap } from 'react-leaflet'

const CircleMarkerMap = ({ points, onClick }) => {
    return (
        <MapContainer
            className="rounded-lg h-full w-full"
            center={new LatLng(100, 100)}
            zoom={13} scrollWheelZoom={true}
        >
            <TileLayer
                attribution='&copy <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <TileLayer
                attribution="Map data &copy; Kartverket"
                url="https://opencache.statkart.no/gatekeeper/gk/gk.open_gmaps?layers=norgeskart_bakgrunn&zoom={z}&x={x}&y={y}"
            />
            <Pane name="circlePoints" style={{ zIndex: 600 }} />
            <MapBoundComponent positions={points?.map(p => new LatLng(p.latitude, p.longitude)) || []} />
            {
                points.map((point, index) => {
                    return <CircleMarker key={index}
                        center={new LatLng(point.latitude, point.longitude)}
                        radius={5}
                        fill={true}
                        fillOpacity={1}
                        eventHandlers={{ click: () => onClick(point.id) }}
                    >
                        <Tooltip direction="top" offset={[0, -20]} opacity={1}>
                            {point.name}
                        </Tooltip>
                    </CircleMarker>
                })
            }
        </MapContainer>
    )
}

const MapBoundComponent = ({ positions }) => {
    const map = useMap()
    useEffect(() => {
        if (positions?.length > 0) {
            map.fitBounds(positions, {
                animate: true,
                padding: [50, 50],
                duration: 100,
                maxZoom: 10,
            })
        }
    }, [])
    return <></>
}

export default CircleMarkerMap