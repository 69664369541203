import { MooringDropdownDto } from 'src/generated-types/mooring-drop-down-dto'
import { IGetDocumentsParams } from '../../../../api/document'
import DocumentsTableGeneric from 'src/components/documents/DocumentsTableGeneric'

export interface IDocumentsTableProps {
  customerId: EntityId
  localityId: EntityId
  documentsParams: IGetDocumentsParams
  showDocumentsHeader?: boolean
  showAddDocumentButton?: boolean
  showEditDocumentButton?: boolean
  disableAdd?: boolean
  height?: number | string
  pageSize?: number
  autoHeight?: boolean
  isDocumentListView?: boolean
  moorings?: MooringDropdownDto[]
  hideMooringColumn?: boolean
}
export const DocumentsTable: React.FC<IDocumentsTableProps> = ({
  customerId,
  localityId,
  documentsParams,
  showDocumentsHeader = true,
  showAddDocumentButton = false,
  showEditDocumentButton = false,
  disableAdd = false,
  height,
  pageSize,
  autoHeight,
  moorings,
  isDocumentListView = false,
  hideMooringColumn
}) => {
  return (
    <DocumentsTableGeneric
      customerId={customerId}
      localityId={localityId}
      documentsParams={{ ...documentsParams, localityId: localityId as EntityId }}
      showAddDocumentButton={showAddDocumentButton}
      showDocumentsHeader={showDocumentsHeader}
      showEditDocumentButton={showEditDocumentButton}
      disableAdd={disableAdd}
      height={height}
      pageSize={pageSize}
      autoHeight={autoHeight}
      moorings={moorings}
      isDocumentListView={isDocumentListView}
      hideMooringColumn={hideMooringColumn}
    />
  )
}
