import { Box, styled, TextField, TextFieldProps } from '@mui/material'
import React from 'react'
import { MUIThemeColors } from 'src/mui-theme'
import { getThemeColor } from 'src/utils'

const AppTextFieldBase = styled(TextField)(({theme})=>({
  '& .MuiInputBase-root': {
    fontWeight: '400',
  },
  '& .MuiFormLabel-root': {
    fontWeight: '400',
    color: getThemeColor(theme,MUIThemeColors.black),
    '&.Mui-focused': {
      color: getThemeColor(theme,MUIThemeColors.primaryMain),
    }
  },
}))

export type AppTextFieldProps = TextFieldProps

export const AppTextField: React.FC<AppTextFieldProps> = ({ InputProps, variant = 'outlined', children, ...rest }) => {
  return (
    <Box paddingY={1} paddingX={1} sx={{ display: rest.type == 'hidden' ? 'none' : undefined, ...rest?.sx }}>
      {
        // @ts-ignore
        <AppTextFieldBase
          variant={variant}
          {...rest}
          InputProps={{
            ...InputProps,
            disableUnderline: true,
          }}
          autoComplete="off"
        >
          {children}
        </AppTextFieldBase>
      }
    </Box>
  )
}
