export const languages = ['en', 'no']
export const NO_VALID_CERTIFICATE = 'NoValidCertificate'
export const MainComponentStatus = {
  Active: 'Active',
  InService: 'In Service',
  InStorage: 'In Storage',
}
export const NOT_FOUND = 'NotFound'
export const SUCCESSFULLY_DELETED = 'SuccessfullyDeleted'
export const SUCCESSFULLY_REMOVED = 'SuccessfullyRemoved'
export const SUCCESSFULLY_ADDED = 'SuccessfullyAdded'
export const SUCCESSFULLY_CREATED = 'SuccessfullyCreated'
export const SUCCESSFULLY_UPDATED = 'SuccessfullyUpdated'
export const SUCCESSFULLY_APPROVED = 'SuccessfullyApproved'
export const SUCCESSFULLY_WITHDREW = 'SuccessfullyWithdrew'
export const INTERNAL_SERVER_ERROR = 'InternalError'
export const ALREADY_EXISTS = 'AlreadyExists'
export const COMPONENT_EXISTS = 'ComponentsExists'
export const CANNOT_DELETE = 'CannotDelete'
export const CANNOT_ADD = 'CannotAdd'
export const POSITIONS_EXIST = 'PositionsExist'
export const LIMIT_EXCEEDED = 'LimitExceeded'
export const CANNOT_DISCARD = 'CannotDiscard'
export const SAME_RULE_ERROR = 'SameRuleError'
export const RULE_RELEASED = 'RuleReleased'
export const RULES_RESTORED = 'RulesRestored'
export const RULES_MERGED = 'RulesMerged'
export const INVALID_END_DATE = 'InvalidEndDate'
export const REPORTS_NOT_COMPLETED = 'ReportsNotCompleted'

export const CUSTOM_PROPERTY_LABELS = {
  CIRCUMFERENCE: 'Circumference',
  PIPE_DIAMETER: 'PipeDiameter',
  LIFE_TIME: 'LifeTime',
  PERIMETER: 'Perimeter',
}

export const WorkAssignmentReportType = {
  1: 'UTLEGGSRAPPORT',
  2: 'FORANKRING INSPEKSJONSRAPPORT',
  3: 'AQS - UTLEGGSRAPPORT',
  4: 'AQS - FORANKRING INSPEKSJONSRAPPORT',
  5: '6 MÅNEDERS KONTROLL',
  6: '12 MÅNEDERS KONTROLL',
  7: '5 ÅRS KONTROLL',
  8: '5 ÅRS KONTROLL m/komponenter',
}
export const APIResultCount = {
  Less: 10,
  More: 1000,
  Max: 100000,
}

export const DiscardComponentId = Math.floor(Math.random() * 1000000000)

export const QuickFilterBounceTime = 500

export const permissionLevel = {
  Locality: ['Read', 'Update'],
  Deviation: ['Read', 'Write', 'Update'],
  Documents: ['Read', 'Write', 'Delete'],
  Service: ['Read', 'Write', 'Update'],
  Task: ['Read', 'Write', 'Update'],
  Mooring: ['Write', 'Delete'],
  Mooring_Deviation: ['Read'],
  MainComponent: ['Read'],
  MainComponent_Deviation: ['Read', 'Write', 'Delete'],
  MainComponent_Document: ['Read', 'Write', 'Delete'],
  MainComponent_Certification: ['Read', 'Write', 'Delete'],
  MainComponent_Report: ['Read', 'Write', 'Delete'],
  WorkAssignment: ['Read', 'Write', 'Delete'],
  ServiceStation: ['Read', 'Write', 'Update', 'Delete'],
  Maintenance: ['Delete', 'Perform'],
  ClientAdminPermission: ['Read']
}

export const permissionBinding = {
  Locality: { Update: ['Locality.Read'] },
  Deviation: { Write: ['Deviation.Read'], Update: ['Deviation.Read'] },
  Documents: { Write: ['Documents.Read'], Delete: ['Documents.Read'] },
  Service: { Write: ['Service.Read'], Update: ['Service.Read'] },
  Task: { Write: ['Task.Read'], Update: ['Task.Read'] },
  MainComponent_Deviation: { Write: ['MainComponent_Deviation.Read', 'MainComponent.Read'], Delete: ['MainComponent_Deviation.Read', 'MainComponent.Read'] },
  MainComponent_Document: { Write: ['MainComponent_Document.Read', 'MainComponent.Read'], Delete: ['MainComponent_Document.Read', 'MainComponent.Read'] },
  MainComponent_Certification: { Write: ['MainComponent_Certification.Read', 'MainComponent.Read'], Delete: ['MainComponent_Certification.Read', 'MainComponent.Read'] },
  MainComponent_Report: { Write: ['MainComponent_Report.Read', 'MainComponent.Read'], Delete: ['MainComponent_Report.Read', 'MainComponent.Read'] },
  WorkAssignment: { Write: ['WorkAssignment.Read'], Delete: ['WorkAssignment.Read'] },
  ServiceStation: { Write: ['ServiceStation.Read'], Update: ['ServiceStation.Read'], Delete: ['ServiceStation.Read'] },
  Maintenance: { Perform: ['Maintenance.Read'], Delete: ['Maintenance.Read'] },
}

export const passwordRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/

export const pageSizes = [5, 10, 20]
