import { FC } from 'react'
import { CommonCarousal } from 'src/components/common/CommonCarousal'
import { VesselCard, VesselDetail } from './VesselCard'
import { Card } from '@mui/material'

const items: Array<VesselDetail> = [
  {
    key: 1,
    name: 'Isbergan',
    certificationDate: new Date(),
    clientName: 'Akva Sea',
    yearBuilt: 2021,
    imageUrl:
      'https://images.ctfassets.net/4v7glkww2pji/22CVefUFGWmv733IdTxbJe/34f433cc461397bcef808fb0037e1a07/735x418_container_vessel.jpg',
  },
  {
    key: 2,
    name: 'NodBot',
    certificationDate: new Date(),
    clientName: 'Sea Flex',
    yearBuilt: 2022,
    imageUrl: 'https://mb.cision.com/Public/15003/3006238/96ad243b117f5efa_800x800ar.jpg',
  },
  {
    key: 3,
    name: 'Kongsvaggon',
    certificationDate: new Date(),
    clientName: 'Nugentran',
    yearBuilt: 2023,
    imageUrl: 'https://www.ship-technology.com/wp-content/uploads/sites/8/2017/10/4-image-10.jpg',
  },
]

export const VesselCardContainer: FC = () => {
  return (
    <Card sx={{ height: '180px', width: '100%' }}>
      <CommonCarousal<VesselDetail> items={items} ContentComponent={VesselCard} />
    </Card>
  )
}
