import { Box } from '@mui/material'
import { useGetCustomersLocalitiesForMap } from '../../api/customer'
import { MapComponent } from './components/map/MapComponent'
import { CenteredSpinner } from '../../components/Spinner'
import { ILocality } from '../../generated-types'

export const LocalitiesMapComponent: React.FC<{ selectedCustomer: EntityId; searchValue: string }> = ({
  selectedCustomer,
  searchValue,
}) => {
  const { data, isLoading } = useGetCustomersLocalitiesForMap(searchValue, selectedCustomer)
  return (
    <Box height={((window.innerHeight - 112) * 2) / 3}>
      {isLoading && <CenteredSpinner />}
      {data && (
        <MapComponent
          navigateLocalityWhenClick={true}
          localities={data as ILocality[]}
          key={'locality-main-page-map'}
          zoomControl={true}
          scrollWheelZoom={true}
          enableBoatController
          enableLocationController
          removeAttribution
          center={[66, 13]}
          zoom={5}
        />
      )}
    </Box>
  )
}
