import { Box, Tooltip } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { z } from 'zod'
import { AppFormProps, AppForm, FormTextField, FormSelectField } from '../../components/FormCore'
import { useZodForm } from '../../hooks/zod-form'
import { ServiceStationType } from '../../enums'
import { UserDetail, getUserDetail } from '../../utils/getUserDetails'
import { useGetCustomerUsers } from '../../api/customer'
import { useState } from 'react'
import { MainButton } from '../../components/MainButton'
import { GenericDialogLayout } from '../../components/GenericDialogLayout'
import { getServiceStationType } from '../../utils/function'

export interface ServiceStationFormProps extends AppFormProps {
  customerId: EntityId
  title: string
  pop: () => void
  onSubmit: (form: any) => void
  isLoading?: boolean
}
export const ServiceStationFrom: React.FC<ServiceStationFormProps> = ({
  onSubmit,
  initialValues,
  customerId,
  title,
  pop,
  isLoading,
}) => {
  const { t: translate } = useTranslation(['product', 'common'])
  const { data: customerUsers } = useGetCustomerUsers(customerId)
  const hasMainComponents =
    initialValues?.inServiceCount ||
    initialValues?.inStockCount ||
    initialValues?.incomingCount ||
    initialValues?.outgoingCount
  const [isDisabledMessageOpen, setIsDisabledMessageOpen] = useState<boolean>(false)

  const schema = z.object({
    name: z.string().nonempty(translate('nameIsRequired', { ns: 'service-station' })),
    serviceStationNumber: z.string().nonempty(translate('numberIsRequired', { ns: 'service-station' })),
    type: z.number(),
    latitude: z
      .string()
      .transform((val, ctx) => {
        const returnVal = parseFloat(val)
        if (returnVal < -90 || returnVal > 90) {
          ctx.addIssue({ code: z.ZodIssueCode.custom, message: translate('latitude_range_error', { ns: 'common' }) })
        }
        return returnVal
      })
      .or(z.number().min(-90).max(90)),
    longitude: z
      .string()
      .transform((val, ctx) => {
        const returnVal = parseFloat(val)
        if (returnVal < -180 || returnVal > 180) {
          ctx.addIssue({ code: z.ZodIssueCode.custom, message: translate('longitude_range_error', { ns: 'common' }) })
        }
        return returnVal
      })
      .or(z.number().min(-180).max(180)),
    managedByUserId: z
      .object({
        id: z.string().nonempty(translate('numberIsRequired', { ns: 'service-station' })),
      })
      .transform(value => value.id),
    customerId: z.number(),
  })

  const form = useZodForm(schema, {
    defaultValues: {
      ...initialValues,
      type: typeof initialValues?.type == 'string' ? ServiceStationType.ServiceStation : initialValues?.type,
      managedByUserId: initialValues?.managedByUser,
      customerId: customerId,
    },
  })

  const serviceStationTypes = Object.keys(ServiceStationType)
    .filter(v => isNaN(Number(v)))
    .map(t => ServiceStationType[t])

  return (
    <GenericDialogLayout
      title={title}
      pop={pop}
      removedCloseButton={false}
      actions={[
        <MainButton
          key="locality-form-button"
          variant="contained"
          disabled={isLoading}
          onClick={() => {
            form.handleSubmit(onSubmit)()
          }}
          data-cy="UpdateLocality_submit"
        >
          {initialValues?.id ? translate('update', { ns: 'common' }) : translate('add', { ns: 'common' })}
        </MainButton>,
      ]}
    >
      <AppForm form={form} hasInitialValues={initialValues}>
        <FormTextField name="name" label={translate('serviceStationName', { ns: 'service-station' })} />
        <FormTextField
          name="serviceStationNumber"
          label={translate('serviceStationNumber', { ns: 'service-station' })}
        />
        <FormTextField type="number" name="latitude" label={translate('latitude', { ns: 'common' })} />
        <FormTextField type="number" name="longitude" label={translate('longitude', { ns: 'common' })} />
        <Tooltip
          arrow
          title={hasMainComponents ? (translate('cannotChangeType', { ns: 'service-station' }) as string) : ''}
          open={isDisabledMessageOpen}
          placement="top"
        >
          <Box onMouseEnter={() => setIsDisabledMessageOpen(true)} onMouseLeave={() => setIsDisabledMessageOpen(false)}>
            <FormSelectField
              name="type"
              data={serviceStationTypes || []}
              label={translate('type', { ns: 'service-station' })}
              getOptionLabel={option => getServiceStationType(option) as string}
              renderOption={(props: any, option) => (
                <Box {...props} key={option}>
                  {getServiceStationType(option)}
                </Box>
              )}
              isOptionEqualToValue={(o, v) => o === v}
              disabled={hasMainComponents}
            />
          </Box>
        </Tooltip>
        <FormSelectField
          name="managedByUserId"
          data={customerUsers!}
          label={translate('managedByUserId', { ns: 'service-station' })}
          getOptionLabel={option => getUserDetail(option, UserDetail.NameAndEmail)}
          renderOption={(props: any, option) => (
            <Box {...props} key={option}>
              {getUserDetail(option, UserDetail.NameAndEmail)}
            </Box>
          )}
          isOptionEqualToValue={(o, v) => o.id === v.id}
        />
      </AppForm>
    </GenericDialogLayout>
  )
}

export default ServiceStationFrom
