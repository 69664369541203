import { GridColumns } from '@mui/x-data-grid-pro'
import { useCallback, useMemo } from 'react'
import { TFunction, useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { deviationsEndpoints, invalidateDeviation, useDeleteDeviation, useGetDeviations } from '../../../api/deviation'
import { AppRoutesNavigation } from '../../../app-routes'
import { CenteredSpinner } from '../../../components/Spinner'
import UpdateDeviationDialog from '../../../dialogs/deviation/UpdateDeviationDialog'
import { IDeviation, IDeviationFilters } from '../../../generated-types/deviation'
import { IDeviationPriority } from '../../../generated-types/deviation-priority'
import { Stack, Theme, useTheme } from '@mui/material'
import { getFormattedDate, getPageSize, getThemeColor } from '../../../utils'
import { UserDetail, getUserDetail } from '../../../utils/getUserDetails'
import { AuthorizationProvider, hasPermission } from '../../../components/common/Authorization'
import { AuthorizationType } from '../../../enums/auth'
import { GridActionButton } from 'src/components/common/GridActionButton'
import { DeleteOutline, EditOutlined } from '@mui/icons-material'
import { deviationQueryKeys } from 'src/query-keys'
import { DynamicTextTypography } from 'src/components/DynamicTextTypography'
import { useDialogCtx } from 'src/hooks/context-hooks'
import { permissionValue } from 'src/enums/permissionValue'
import { useNavigation } from 'src/context/NavigationContext'
import { toast } from 'react-toastify'
import { Padding } from 'src/styles/paddingComponent'
import { Chip } from 'src/components/common/Chip'
import { MUIThemeColors } from 'src/mui-theme'
import GenericTable from 'src/components/table/GenericTable'
import { MaintenanceType } from 'src/enums'
import { RowMaintenanceTypeImageBox } from 'src/components/locality-dashboard/LocalityMaintenanceGrid'
import { invalidateCustomer } from 'src/api/customer'
import DeleteWarningIcon from '@mui/icons-material/WarningAmberRounded'

export interface IDeviationsTableProps {
  customerId: EntityId
  localityId?: EntityId
  mooringId?: EntityId
  positionId?: EntityId
  componentId?: EntityId
  mainComponentId?: EntityId
  localityFrequencyId?: EntityId
  localityFrequencyGroupId?: any
  columns?: GridColumns<IDeviation>
  disabled?: boolean
  queryParams?: any
  scheduledTaskId?: EntityId
  handleRowClick?: (cell: any) => void
  filterDeviation?: IDeviationFilters
  pop?: () => void
  autoHeight?: boolean
  height?: number
  enablePagination?: boolean
}

export interface IDeviationTableContainerProps extends IDeviationsTableProps {
  updatePermissions: number[]
  deletePermissions: number[]
  permission?: number
  enableDelete?: boolean
}

const DeviationsDataGrid: React.FC<IDeviationsTableProps> = ({
  customerId,
  localityId,
  mooringId,
  positionId,
  componentId,
  mainComponentId,
  localityFrequencyId,
  localityFrequencyGroupId,
  columns,
  scheduledTaskId,
  handleRowClick,
  filterDeviation,
  autoHeight,
  height,
  enablePagination = true,
}) => {
  const { data, isLoading } = useGetDeviations(
    customerId,
    localityId,
    mooringId,
    positionId,
    componentId,
    mainComponentId,
    localityFrequencyId,
    localityFrequencyGroupId,
    scheduledTaskId,
    0,
    1000,
    filterDeviation,
  )

  if (isLoading) return <CenteredSpinner />

  return (
    <GenericTable
      id={'deviations-table-pagination-client-side'}
      columns={columns!}
      getRowClassName={params =>
        params.indexRelativeToCurrentPage % 2 == 0 ? 'even hover-pointer' : 'odd hover-pointer'
      }
      data={(data?.items as IDeviation[]) ?? []}
      loading={isLoading}
      onCellClick={handleRowClick}
      getRowHeight={() => 'auto'}
      hideDefaultFooterPagination={true}
      enablePagination={enablePagination}
      autoHeight={autoHeight}
      height={height}
    />
  )
}

export const static_column = (
  t: TFunction<('common' | 'deviation')[], undefined>,
  theme: Theme,
  navigateTo?: (path: string) => void,
  customerId?: EntityId,
  localityId?: EntityId,
): GridColumns => [
  { field: 'title', headerName: t('title', { ns: 'common' }), flex: 1 },
  {
    field: 'createdBy',
    headerName: t('createdBy', { ns: 'common' }),
    minWidth: 200,
    valueGetter: ({ row }) => {
      return row.createdByUser ? getUserDetail(row.createdByUser, UserDetail.FullName) : '-'
    },
  },
  {
    field: 'description',
    headerName: t('description', { ns: 'common' }),
    flex: 1.5,
    renderCell: ({ row }) => (
      <DynamicTextTypography text={row.description} variant="body1" maxLength={20} fontWeight={500} />
    ),
  },
  {
    field: 'placement',
    headerName: t('placement', { ns: 'common' }),
    minWidth: 200,
    renderCell: ({ row }) => {
      const placementArray = Object.entries(JSON.parse(row.placement))
        .filter(([, item]) => item)
        .map(([, option]) => option)
      return (
        <Stack>
          <DynamicTextTypography maxLength={(placementArray[0] as string)?.length} text={placementArray.join(' -> ')} />
        </Stack>
      )
    },
  },
  {
    field: 'priority',
    headerName: t('priority', { ns: 'common' }),
    flex: 0.5,
    minWidth: 100,
    renderCell: value =>
      value.value == IDeviationPriority.Critical ? (
        <Chip
          label={t('critical', { ns: 'common' })}
          variant="filled"
          sx={{
            backgroundColor: getThemeColor(theme, MUIThemeColors.errorMain),
            color: 'white !important',
            height: '24px',
            borderRadius: '4px',
          }}
        />
      ) : (
        <Chip
          label={t('normal', { ns: 'common' })}
          variant="outlined"
          sx={{ borderColor: getThemeColor(theme, MUIThemeColors.secondaryLight), height: '24px', borderRadius: '4px' }}
        />
      ),
  },
  {
    field: 'origin',
    headerName: t('origin', { ns: 'common' }),

    renderCell: ({ row }) => {
      const deviation = row as IDeviation
      return deviation?.localityFrequencyDeviations?.length != 0 ? (
        <>
          <Padding.pr4>{RowMaintenanceTypeImageBox({ type: MaintenanceType.Service })}</Padding.pr4>
          {deviation?.localityFrequencyDeviations?.map(localityFrequencyDeviation => (
            <DynamicTextTypography
              key={localityFrequencyDeviation.id}
              onClick={e => {
                e.stopPropagation()
                e.preventDefault()
                if (navigateTo && customerId && localityId) {
                  navigateTo(
                    AppRoutesNavigation.Localities.localityService(
                      customerId,
                      localityId,
                      localityFrequencyDeviation?.localityFrequencyId as EntityId,
                    ),
                  )
                }
              }}
              text={localityFrequencyDeviation?.title}
              maxLength={12}
            />
          ))}
        </>
      ) : deviation?.scheduledTask?.title ? (
        <>
          <Padding.pr4>{RowMaintenanceTypeImageBox({ type: MaintenanceType.Task })}</Padding.pr4>
          <DynamicTextTypography
            onClick={e => {
              e.stopPropagation()
              e.preventDefault()
              if (navigateTo && customerId && localityId) {
                navigateTo(
                  AppRoutesNavigation.Localities.task(customerId, localityId, deviation?.scheduledTask?.id as EntityId),
                )
              }
            }}
            text={deviation?.scheduledTask?.title}
            maxLength={12}
          />
        </>
      ) : (
        '-'
      )
    },
    minWidth: 170,
  },
  {
    field: 'status',
    headerName: t('status', { ns: 'common' }),
    renderCell: ({ value }) =>
      !value ? (
        <Chip
          label={t('open', { ns: 'common' })}
          variant="outlined"
          sx={{
            borderColor: getThemeColor(theme, MUIThemeColors.secondaryLight),
            height: '26px',
            borderRadius: '4px',
            width: '69px',
          }}
        />
      ) : (
        <Chip
          label={t('closed', { ns: 'common' })}
          variant="outlined"
          sx={{ borderColor: getThemeColor(theme, MUIThemeColors.secondaryLight), height: '26px', borderRadius: '4px' }}
        />
      ),
    minWidth: 50,
  },

  {
    field: 'dueDate',
    headerName: t('dueDate', { ns: 'common' }),
    flex: 0.6,
    valueGetter: value => {
      return getFormattedDate(new Date(value.value))
    },
  },
]

export const DeviationsTable: React.FC<IDeviationTableContainerProps> = ({
  customerId,
  localityId,
  mooringId,
  positionId,
  componentId,
  mainComponentId,
  localityFrequencyId,
  scheduledTaskId,
  disabled,
  queryParams,
  handleRowClick,
  updatePermissions,
  filterDeviation,
  pop,
  autoHeight,
  height,
  enablePagination = true,
  deletePermissions,
  permission,
  enableDelete = false,
}) => {
  const { showDialog, showConfirmDialog } = useDialogCtx()
  const { navigateTo } = useNavigation()
  const theme = useTheme()
  const { t } = useTranslation(['deviation', 'common'])
  const { serviceStationId } = useParams()

  const mutation = useDeleteDeviation(customerId)
  const onDelete = (id: EntityId) => {
    showConfirmDialog(
      t('deleteDeviation'),
      t('areYouSureDeleteDeviation'),
      {
        acceptText: t('yes', { ns: 'common' }),
        cancelText: t('no', { ns: 'common' }),
      },
      pop => {
        mutation.mutate(id, {
          onSuccess: () => {
            toast.success(t('deviationDeletedSuccessfully'))
            invalidateDeviation.getDeviationsAdmin()
            invalidateDeviation.invalidateAllDeviations(customerId, localityId, undefined, undefined)
            invalidateCustomer.getCustomersActivityCounts(customerId)
            pop()
          },
        })
      },
      {
        Icon: (
          <DeleteWarningIcon
            sx={{ height: '43px', width: '48px', color: theme.palette.errorMain[theme.palette.mode] }}
          />
        ),
        iconBGColor: '#D32F2F4D',
        buttonTextColor: theme.palette.white[theme.palette.mode],
        buttonBGColor: theme.palette.errorMain[theme.palette.mode],
      },
    )
  }

  const updateDeviation = useCallback(
    async (deviation: IDeviation) => {
      const updated = await showDialog(
        UpdateDeviationDialog,
        {
          componentProps: {
            customerId: customerId as EntityId,
            localityId: localityId,
            deviation: deviation,
          },
        },
        undefined,
        true,
      )
      if (!updated) return
    },
    [customerId, localityId],
  )

  const columns: GridColumns = useMemo(
    () => [
      ...static_column(t, theme, navigateTo, customerId, localityId as EntityId),
      {
        field: 'actions',
        headerName: '',
        minWidth: 100,
        renderCell: ({ id, row }) => (
          <Padding.p6
            display={'flex'}
            flexDirection={'row'}
            justifyContent={'space-evenly'}
            width={100}
            marginBottom={'2px'}
          >
            <AuthorizationProvider permissions={updatePermissions} type={AuthorizationType.Disabled}>
              <GridActionButton
                disabled={disabled ? disabled : row.status}
                onClick={e => {
                  e.stopPropagation()
                  updateDeviation(row)
                }}
                buttonIcon={<EditOutlined />}
                width="32px"
                data-cy="UpdateDeviation"
                height="32px"
                shadowWeight={0}
              />
            </AuthorizationProvider>
            {enableDelete && (
              <GridActionButton
                disabled={disabled ? disabled : row.status}
                key={'delete'}
                buttonIcon={<DeleteOutline />}
                onClick={e => {
                  e.stopPropagation()
                  onDelete(id)
                }}
                width="32px"
                data-cy="UpdateDeviation"
                height="32px"
                shadowWeight={0}
              />
            )}
          </Padding.p6>
        ),
      },
    ],
    [disabled, t, updateDeviation, updatePermissions],
  )

  const onRowClick = useCallback(
    cell => {
      pop && pop()
      if (
        disabled ||
        (permission &&
          !hasPermission(
            [...updatePermissions, ...deletePermissions, permissionValue.Locality.MainComponent.Deviation.Read],
            permission,
          ))
      )
        return
      if (localityId) navigateTo(AppRoutesNavigation.Localities.deviation(customerId, localityId as EntityId, cell.id))
      else if (serviceStationId)
        navigateTo(AppRoutesNavigation.serviceStation.deviation(customerId, serviceStationId as EntityId, cell.id))
      else navigateTo(AppRoutesNavigation.Customer.deviation(customerId, cell.id))
    },
    [customerId, disabled, localityId, navigateTo],
  )

  if (mooringId || positionId || componentId || mainComponentId || localityFrequencyId || scheduledTaskId) {
    return (
      <DeviationsDataGrid
        customerId={customerId}
        localityId={localityId}
        mooringId={mooringId}
        positionId={positionId}
        componentId={componentId}
        mainComponentId={mainComponentId}
        localityFrequencyId={localityFrequencyId}
        scheduledTaskId={scheduledTaskId}
        columns={columns}
        disabled={disabled}
        handleRowClick={handleRowClick ?? onRowClick}
        enablePagination={enablePagination}
        autoHeight={autoHeight}
        height={height}
        filterDeviation={filterDeviation}
      />
    )
  }

  return (
    <GenericTable
      id={'deviations-table-pagination-server-side'}
      queryUrl={deviationsEndpoints.getDeviations(customerId)}
      generateQueryKey={(page: number, pageSize: number) =>
        deviationQueryKeys.GET_DEVIATIONS(customerId, {
          ...queryParams,
          page,
          pageSize,
          ...filterDeviation,
        })
      }
      getRowClassName={params =>
        params.indexRelativeToCurrentPage % 2 == 0 ? 'even hover-pointer' : 'odd hover-pointer'
      }
      enableServerPagination
      hideDefaultFooterPagination
      pageSize={height ? getPageSize(height / 44) : undefined}
      queryParams={{ ...queryParams, ...filterDeviation }}
      rowTopSpacing={0}
      onRowClick={handleRowClick ?? onRowClick}
      columns={columns}
      noRowsMessage={t('localityHasNoDeviations', { ns: 'deviation' })}
      enablePagination={enablePagination}
      autoHeight={autoHeight}
      height={height}
    />
  )
}
