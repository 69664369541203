import { Box, Typography } from '@mui/material'
import { ICustomer } from '../../../generated-types'

interface ICustomerDetail {
  customerComponent: ICustomer
}

const MainComponentDetail = ({ customerComponent }: ICustomerDetail) => {

    return (
      <Box className="bg-background rounded-md  shadow-md " p={1}>
        <div className=" w-full flex-wrap flex-col-reverse">
          <div className="flex flex-row">
            <Typography color="primary" variant="h4">
              {customerComponent?.name}
            </Typography>
            <div className="flex flex-col-reverse px-3  mb-1">
              <Typography color="#90DAB9">#{customerComponent?.organizationNr}</Typography>
            </div>
          </div>
        </div>
      </Box>
    ) 
}

export default MainComponentDetail
