import { Avatar, CardContent, CardHeader, CardProps, Stack, TypographyProps, styled, useTheme } from '@mui/material'
import { ReactElement, ReactNode } from 'react'
import { GridActionButton } from './common/GridActionButton'
import KebabMenu from './common/KebabMenu'
import { StyledCard } from './GenericCardMinimalVersion'
import { Spinner } from './Spinner'
import { DynamicTextTypography } from './DynamicTextTypography'

interface ActionBarElement {
  label: string
  onClick: () => void
  Icon: ReactNode
}

const CustomStyleCard = styled(StyledCard)({
  '& .MuiCardHeader-root': {
    paddingTop: '8px',
  }
})

export interface IGenericCard extends CardProps {
  title?: string
  titleTypographyProps?: TypographyProps
  headerIcon?: ReactNode
  actions: Array<ActionBarElement> | ReactElement
  minHeight?: string | number
  maxHeight?: string | number
  minWidth?: string | number
  maxWidth?: string | number
  backgroundColor?: string
  backgroundImage?: string
  iconColor?: string
  isKebabMenu?: boolean
  isSelected?: boolean
  isLoading?: boolean
  maxLength?: number
}

const GenericCard: React.FC<IGenericCard> = ({
  title,
  titleTypographyProps,
  headerIcon,
  children,
  actions,
  minHeight,
  maxHeight,
  minWidth,
  maxWidth,
  backgroundColor,
  backgroundImage,
  isKebabMenu,
  isSelected,
  isLoading,
  maxLength,
  sx,
  ...rest
}) => {
  const theme = useTheme()
  return (
    <CustomStyleCard
      elevation={3}
      style={{ backgroundColor: isSelected ? theme.palette.primaryMain[theme.palette.mode] : backgroundColor }}
      sx={{
        minHeight,
        maxHeight,
        minWidth,
        maxWidth,
        overflowY: 'auto',
        backgroundImage: backgroundImage,
      }}
      {...rest}
    >
      <CardHeader
        sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}
        action={
          <Stack direction={'row'} spacing={1} className="pr-8-core pl-8-core">
            {!isKebabMenu ? (
              Array.isArray(actions) ? (
                actions.map((item, index) => (
                  <GridActionButton
                    key={index}
                    onClick={item.onClick}
                    toolTipTitle={item.label}
                    bgColor={
                      isSelected
                        ? theme.palette.white[theme.palette.mode]
                        : theme.palette.secondaryLight[theme.palette.mode]
                    }
                    height="24px"
                    width="24px"
                    buttonIcon={item.Icon}
                    color="primary"
                    sx={{
                      '&:hover': {
                        backgroundColor: isSelected
                          ? theme.palette.secondaryLight[theme.palette.mode]
                          : theme.palette.white[theme.palette.mode],
                      },
                    }}
                  />
                ))
              ) : (
                actions
              )
            ) : (
              <KebabMenu actions={actions as []} isSelected={isSelected} />
            )}
          </Stack>
        }
        title={
          title ? (
            <Stack direction={'row'} justifyContent={'flex-start'} alignItems={'center'} gap={1}>
              {headerIcon && (
                <Avatar
                  sx={{ height: '24px', width: '24px', bgcolor: theme.palette.silent[theme.palette.mode], opacity: 50 }}
                  variant="rounded"
                >
                  {headerIcon}
                </Avatar>
              )}
              <DynamicTextTypography
                text={title}
                maxLength={maxLength}
                color={
                  isSelected
                    ? `${theme.palette.white[theme.palette.mode]} !important`
                    : theme.palette.primaryDark[theme.palette.mode]
                }
                variant="subtitle1"
                fontWeight={600}
                {...titleTypographyProps}
              />
            </Stack>
          ) : (
            <></>
          )
        }
      />
      <CardContent>
        {isLoading ? (
          <Stack direction={'column'} justifyContent={'center'} alignItems={'center'} minHeight={'100%'}>
            <Spinner />
          </Stack>
        ) : (
          children
        )}
      </CardContent>
    </CustomStyleCard>
  )
}

export default GenericCard
