import { useState, useEffect, useRef, ReactNode, useCallback } from 'react'
import Tabs from '@mui/material/Tabs'
import Box from '@mui/material/Box'
import {
  Divider,
  Icon,
  Menu,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
  TypographyProps,
} from '@mui/material'
import { useTheme } from '@mui/material'
import { MdMoreVert } from 'react-icons/md'
import { useTranslation } from 'react-i18next'
import { DataContent, StyledAppBar, StyledTab, TabContent, TabIcon } from './TabView.style'
import { Flex } from 'src/styles/flexComponent'
import { StyledIconButton } from '../table/GenericTable'

interface Title {
  title: string
  content?: React.ReactNode
  titleProps?: TypographyProps
}
interface ToolBarElement {
  label: string
  onClick: () => void
  Icon: ReactNode
  disabled?: boolean
  iconColor?: string
}
export interface IArrayItem {
  id: number
  header: Title
  content?: React.ReactNode
}
interface ITabPanelProps {
  data: Array<IArrayItem>
  height?: string | number
  width?: number | string
  toolbarOptions?: Array<ToolBarElement> | Array<ReactNode>
  uniqueId: string
  noDataContent?: React.ReactNode
  selectedTabId?: number
  setSelectedTabId?: (id: number) => void
}

interface TabPanelProps {
  children?: React.ReactNode
  dir?: string
  index: number
  value: number
  height: string | number
}
const TabPanel: React.FC<TabPanelProps> = ({ children, value, index, height }) => {
  return value == index ? (
    <DataContent spacing={1} height={height}>
      {children}
    </DataContent>
  ) : (
    <></>
  )
}

const TabView: React.FC<ITabPanelProps> = ({
  data,
  height = 200,
  width,
  toolbarOptions,
  uniqueId,
  noDataContent,
  selectedTabId,
  setSelectedTabId,
}) => {
  const appBarRef = useRef(null)
  const tabsRef = useRef(null)
  const theme = useTheme()
  const [tabComponent, setTabComponent] = useState({
    displayTabs: [] as Array<IArrayItem>,
    extraTabs: [] as Array<IArrayItem>,
    selectedTabId: null as number | null,
  })
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const { t } = useTranslation(['common'])

  useEffect(() => {
    if (selectedTabId) {
      setTabComponent(prev => ({ ...prev, selectedTabId: selectedTabId }))
    }
  }, [selectedTabId])

  const calcMaxTabs = useCallback(() => {
    const tabWidth = (tabsRef.current as unknown as HTMLElement).offsetWidth
    return tabWidth > 160 ? Math.floor(tabWidth / 160) : 1
  }, [tabsRef.current, data])

  useEffect(() => {
    const MAX_NUMBER_OF_TABS = calcMaxTabs()
    renderTabs(MAX_NUMBER_OF_TABS)
  }, [data, tabsRef.current]) //default tabs set and make tabs dynamically for support width changes

  useEffect(() => {
    if (tabComponent.selectedTabId != null) {
      handleSaveToLocal([...tabComponent.displayTabs, ...tabComponent.extraTabs], tabComponent.selectedTabId as number)
    }
  }, [tabComponent])

  window.onbeforeunload = useCallback(() => {
    handleSaveToLocal([...tabComponent.displayTabs, ...tabComponent.extraTabs], tabComponent.selectedTabId as number)
    window.onbeforeunload = null
  }, [tabComponent])

  const handleSaveToLocal = useCallback(
    async (tabsVal: IArrayItem[], val: number) => {
      if (tabsVal.length == 0) return
      sessionStorage.setItem(uniqueId, JSON.stringify(tabsVal.map(tab => tab.id)))
      sessionStorage.setItem(uniqueId + '-selectedElement', JSON.stringify(val))
    },
    [uniqueId],
  )

  const renderTabs = useCallback(
    async (MAX_NUMBER_OF_TABS: number) => {
      const tabItems = await JSON.parse(sessionStorage.getItem(uniqueId) || '[]')
      const selectedTab = await JSON.parse(sessionStorage.getItem(uniqueId + '-selectedElement') as string)
      if (tabItems.length > 0 && selectedTab != null && data.length == tabItems.length) {
        const allTabs = [
          ...tabItems
            .map((id: number) => [...(data ?? [])].find(item => item?.id == id))
            .filter(item => item != undefined),
          ...data.filter(item => !tabItems.includes(item?.id)),
        ]
        setTabComponent({
          displayTabs: allTabs.slice(0, MAX_NUMBER_OF_TABS),
          extraTabs: allTabs.slice(MAX_NUMBER_OF_TABS, allTabs.length),
          selectedTabId: selectedTab as number,
        })
      } else {
        setTabComponent({
          displayTabs: data.slice(0, MAX_NUMBER_OF_TABS),
          extraTabs: data.slice(MAX_NUMBER_OF_TABS, data.length),
          selectedTabId: data[0].id as number,
        })
      }
      setSelectedTabId && setSelectedTabId(selectedTab as number)
    },
    [uniqueId, data],
  ) //render tabs based on width changes

  const handleChange = useCallback((event: React.SyntheticEvent, newValue: number) => {
    setTabComponent(prev => ({ ...prev, selectedTabId: newValue }))
    if (setSelectedTabId) {
      setSelectedTabId(newValue)
    }
  }, [])

  const handleOptionChange = useCallback(
    async (item: IArrayItem) => {
      const index = tabComponent.displayTabs.findIndex(tab => tab.id == tabComponent.selectedTabId)
      const newDisplayTabs = [...tabComponent.displayTabs]
      newDisplayTabs.splice(index + 1, 0, item)
      let poppedItem: IArrayItem | undefined = {} as IArrayItem
      if (index + 1 == tabComponent.displayTabs.length) {
        poppedItem = newDisplayTabs.shift()
      } else {
        poppedItem = newDisplayTabs.pop()
      }
      const newExtraTabs = [...tabComponent.extraTabs.filter(tab => tab.id != item.id)]
      if (poppedItem) {
        newExtraTabs.unshift(poppedItem)
      }
      setTabComponent({
        displayTabs: newDisplayTabs ?? [],
        extraTabs: newExtraTabs ?? [],
        selectedTabId: item.id as number,
      })
      setAnchorEl(null)
    },
    [tabComponent],
  )

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <Box width={'100%'}>
      <StyledAppBar position="static" ref={appBarRef}>
        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2} width={'100%'}>
          <Tabs
            sx={{ flexGrow: 1 }}
            ref={tabsRef}
            value={tabComponent.selectedTabId}
            onChange={handleChange}
            textColor="primary"
            indicatorColor="primary"
            TabIndicatorProps={{ style: { height: '4px' } }}
            scrollButtons={'auto'}
            visibleScrollbar
          >
            {tabComponent.displayTabs?.map((item, index) => {
              return (
                <StyledTab
                  isSelected={tabComponent.selectedTabId == item.id}
                  value={item.id}
                  label={
                    <TabContent
                      direction="row"
                      justifyContent="space-around"
                      alignItems="center"
                      spacing={2}
                      width={'196px'}
                    >
                      <Typography
                        variant="subtitle1"
                        color={
                          tabComponent.selectedTabId == item.id
                            ? theme.palette.primaryMain[theme.palette.mode]
                            : theme.palette.black[theme.palette.mode]
                        }
                        fontWeight={tabComponent.selectedTabId == item.id ? 600 : 400}
                        {...item.header.titleProps}
                      >
                        {item.header.title}
                      </Typography>
                      <Typography>{item.header.content}</Typography>
                    </TabContent>
                  }
                  key={index}
                  wrapped
                />
              )
            })}
          </Tabs>
          <Flex.Row alignItems={'flex-end'} justifyContent={'center'} gap={1} p={'12px'}>
            {toolbarOptions?.map((action, index) => {
              if (typeof action === 'object' && (action as ToolBarElement)?.label != undefined) {
                return (
                  <Tooltip title={action.label} key={index}>
                    <StyledIconButton onClick={action.onClick} key={index}>
                      {action.Icon}
                    </StyledIconButton>
                  </Tooltip>
                )
              }
              return action
            })}
            {tabComponent.extraTabs.length > 0 && (
              <>
                <TabIcon onClick={handleClick}>
                  <Icon>
                    <MdMoreVert />
                  </Icon>
                </TabIcon>
                <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
                  {tabComponent.extraTabs.map((item, index) => (
                    <MenuItem
                      key={index}
                      value={item.id}
                      onClick={() => {
                        handleOptionChange(item)
                      }}
                    >
                      {item.header.title}
                    </MenuItem>
                  ))}
                </Menu>
              </>
            )}
          </Flex.Row>
        </Stack>
        <Divider />
      </StyledAppBar>
      {data && data.length != 0 ? (
        data.map((item, index) => {
          return (
            <TabPanel value={tabComponent.selectedTabId as number} index={item.id} key={index} height={height}>
              {item.content}
            </TabPanel>
          )
        })
      ) : (
        <DataContent height={height} direction="row" justifyContent="center" alignItems="center" spacing={2}>
          {noDataContent ?? t('noDataFound')}
        </DataContent>
      )}
    </Box>
  )
}
export default TabView
