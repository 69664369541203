import {
  useUpdateMainComponent,
  invalidateMainComponentCache,
  useGetMainComponentsByMainComponentId,
} from '../../../api/main-component'
import { IMainComponent } from '../../../generated-types/main-component'
import MainComponentForm from './MainComponentForm'
import { ShowDialogProps } from '../../../show-dialog'
import { useTranslation } from 'react-i18next'
import { getTranslatedText } from '../../../utils/translations'
import { toast } from 'react-toastify'

interface UpdateMainComponentProps extends ShowDialogProps {
  id: EntityId
  mainComponentTypeName: string
  customerId: EntityId
}

export const UpdateMainComponentDialog: React.FC<UpdateMainComponentProps> = ({
  pop,
  id,
  mainComponentTypeName,
  customerId,
}) => {
  const { t: translate } = useTranslation(['main-component', 'common'])
  const mutation = useUpdateMainComponent(customerId as EntityId, id as EntityId)
  const onSubmit = async form => {
    await mutation.mutateAsync(form, {
      onSuccess: () => {
        invalidateMainComponentCache.getMainComponentsByComponentType(customerId, form.typeId)
        invalidateMainComponentCache.getMainComponentByIdAdmin(customerId, id)
        invalidateMainComponentCache.getMainComponentById(customerId as EntityId, id as EntityId)
        invalidateMainComponentCache.getEquipment(customerId)
        toast.success(translate('successfullyUpdated', { ns: 'common-api-message' }))
        pop(true)
      },
      onError: () => {
        toast.error(translate('unExpectedError', { ns: 'common-api-message' }))
      },
    })
  }

  const { data } = useGetMainComponentsByMainComponentId(customerId, id)

  return (
    <>
      {data && (
        <MainComponentForm
          title={
            mainComponentTypeName
              ? `${translate('updateMainComponent')} - ${getTranslatedText(mainComponentTypeName)}`
              : translate('updateMainComponent')
          }
          onSubmit={onSubmit}
          onCancel={() => pop()}
          initialValues={{ ...data, mainComponentTypeName: mainComponentTypeName } as IMainComponent}
          customerId={customerId}
        />
      )}
    </>
  )
}
