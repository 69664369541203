import { Grid } from '@mui/material'
import { CenteredSpinner } from '../../components/Spinner'
import { ICustomer } from '../../generated-types'
import CircleMarkerMap from '../localities/components/map/CircleMarkerMap'
import { useNavigate } from 'react-router-dom'
import { AppRoutesNavigation } from '../../app-routes'

export const ServiceStationMapComponent: React.FC<{ selectedCustomer: ICustomer | undefined; isLoading: boolean }> = ({
  isLoading,
  selectedCustomer,
}) => {
  const navigate = useNavigate()
  return (
    <Grid item md={5} lg={5} sm={12} className="pl-16-core" height={window.innerHeight - 112}>
      {isLoading && <CenteredSpinner />}
      {selectedCustomer?.serviceStations && (
        <CircleMarkerMap
          onClick={(id) => {
            navigate(AppRoutesNavigation.serviceStation.dashboard(selectedCustomer.id, id))
          }}
          points={selectedCustomer?.serviceStations}
        />
      )}
    </Grid>
  )
}
