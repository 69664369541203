import { useTranslation } from 'react-i18next'
import { useUpdateComponent, invalidateComponentsCache, useGetComponent } from 'src/api/components'
import { invalidatePositionCache } from 'src/api/positions'
import { IComponent } from 'src/generated-types'
import { ComponentForm } from './ComponentForm'
import { CenteredSpinner } from 'src/components/Spinner'
import { ShowDialogProps } from 'src/show-dialog'

interface UpdateComponentProps extends ShowDialogProps {
    customerId: EntityId
    localityId: EntityId
    mooringId: EntityId
    positionId: EntityId
    id: EntityId
}

export const UpdateComponentDialog: React.FC<UpdateComponentProps> = ({
    pop,
    customerId,
    localityId,
    mooringId,
    positionId,
    id,
}) => {
    const { t } = useTranslation(['component', 'common'])

    const mutation = useUpdateComponent(customerId, positionId)
    const onSubmit = async form => {
        form.positionId = positionId
        if (form.overriddenLifetime == '') form.overriddenLifetime = 0
        if (form.minimumBreakingLoad == '') form.minimumBreakingLoad = 0

        await mutation.mutateAsync(form, {
            onSuccess: () => {
                invalidateComponentsCache.getComponent(customerId, positionId as EntityId, id)
                invalidateComponentsCache.getComponents(customerId, positionId as EntityId)
                invalidatePositionCache.getPositions(customerId, localityId, mooringId as EntityId)
                pop()
            }
        })
    }

    const { data, isLoading } = useGetComponent(customerId, positionId, id)

    if (isLoading) return <CenteredSpinner />

    return (
        <ComponentForm
            title={t('updateComponent', { ns: 'component' })}
            onSubmit={onSubmit}
            onCancel={() => pop()}
            initialValues={data as IComponent}
            customerId={customerId}
            positionId={positionId}
            isLoading={isLoading}
        />
    )
}
