import { Box } from '@mui/material'
import { GridActionsCellItem, GridColumns, GridNativeColTypes } from '@mui/x-data-grid-pro'
import { t } from 'i18next'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MdCopyAll, MdDelete, MdEdit, MdSend } from 'react-icons/md'
import { toast } from 'react-toastify'
import {
  invalidateMainComponentCache,
  mainEndpoints,
  useAddMainComponentToLocality,
  useDeleteMainComponentById,
  useGetExportToExcel,
  useUpdateMainComponentToOutgoing,
} from '../../../api/main-component'
import GenericTable from '../../../components/table/GenericTable'
import { CANNOT_DISCARD, COMPONENT_EXISTS } from '../../../constant'
import { MainComponentStatus } from '../../../enums'
import { IMainComponent } from '../../../generated-types/main-component'
import { downloadFileLocal } from '../../../utils/downloadFile'
import { MainComponentSendModalComponent } from '../../localities/positions/components/MainComponentSendModal'
import { NO_VALID_CERTIFICATE } from '../../../constant'
import { invalidateMainComponentTypeCache, useDeleteMainComponentType } from '../../../api/main-component-type'
import { invalidateCertificateCache } from '../../../api/Certificates'
import { SendToLocalityDialog } from '../../serviceStations/SendToLocalityDialog'
import { UpdateMainComponentDialog } from './UpdateMainComponentDialog'
import { CreateMainComponentDialog } from './CreateMainComponentDialog'
import { getCageName } from '../../../utils/function'
import { UpdateMainComponentTypeDialog } from '../main-component-types/CustomerUpdateMainComponentTypeDialog'
import { AppRoutesNavigation } from '../../../app-routes'
import { useCustomerCtx, useDialogCtx } from '../../../hooks/context-hooks'
import { hasPermission } from 'src/components/common/Authorization'
import { permissionValue } from 'src/enums/permissionValue'
import { invalidatePositionCache } from 'src/api/positions'
import { DynamicTextTypography } from 'src/components/DynamicTextTypography'
import { AddOutlined, DeleteOutline, EditOutlined } from '@mui/icons-material'
import { useNavigation } from 'src/context/NavigationContext'

interface MainComponentDetailTableProps { 
  customerId: EntityId
  componentTypeId: number
  onClickMethod: () => void
  mainComponentTypeName?: string
  localityId: EntityId
  mooringId: EntityId
  positionId: EntityId
  itemCount: number
}

export const MainComponentDetailTable: React.FC<MainComponentDetailTableProps> = ({
  customerId,
  componentTypeId,
  onClickMethod,
  mainComponentTypeName,
  localityId,
  mooringId,
  positionId,
  itemCount
}) => {
  const { t: translate } = useTranslation(['main-component', 'common', 'main-component-type'])
  const { navigateTo } = useNavigation()
  const { showDialog, showConfirmDialog } = useDialogCtx()
  const { permission } = useCustomerCtx()

  const onUpdate = useCallback(
    async id => {
      const added = await showDialog(UpdateMainComponentDialog, {
        opts: {
          maxWidth: 'md',
          fullWidth: true,
        },
        componentProps: {
          id: id as EntityId,
          mainComponentTypeName: mainComponentTypeName,
          customerId: customerId as EntityId,
        },
      }, undefined, true)
      if (!added) return
    },
    [mainComponentTypeName],
  )
  const onClone = useCallback(
    async row => {
      const { serialNumber, name, id, certificates, ...rest } = row
      const added = await showDialog(CreateMainComponentDialog, {
        opts: {
          maxWidth: 'md',
          fullWidth: true,
        },
        componentProps: {
          customerId: customerId as EntityId,
          initialValues: {
            componentTypeId: componentTypeId,
            ...rest,
            positionId: null,
            certificateNumber: null,
            certificateSupplierId: null,
            certificateDocumentType: 1,
            validUntil: null,
            certificateDate: null,
            certificateFileId: null,
          },
          mainComponentTypeName: mainComponentTypeName,
        },
      }, undefined, true)
      if (!added) return
    },
    [customerId, mainComponentTypeName],
  )

  const { mutateAsync, isLoading: isDeleting, error: discardError } = useDeleteMainComponentById(customerId as EntityId)
  const {
    mutateAsync: deleteMainComponentType,
    isLoading: isDeletingmainComponentType,
    error: errordeleteMainComponentType,
  } = useDeleteMainComponentType(customerId as EntityId)
  const exportMutation = useGetExportToExcel(customerId as EntityId)

  useEffect(() => {
    if (discardError && discardError['data'] == CANNOT_DISCARD) {
      toast(translate('cannotDiscardActiveMainComponent'), { type: 'error' })
    }
    if (errordeleteMainComponentType && errordeleteMainComponentType['data'] == COMPONENT_EXISTS) {
      toast(translate('component-exists', { ns: 'main-component-type' }), { type: 'error' })
    }
  }, [discardError, errordeleteMainComponentType])

  const onDeleteMainComponent = (id: EntityId) => {
    showConfirmDialog(
      t('discardMainComponent', { ns: 'main-component' }),
      t('areYouSureDiscardMainComponent', { ns: 'main-component' }),
      {
        acceptText: t('yes', { ns: 'common' }),
        cancelText: t('no', { ns: 'common' }),
      },
      async pop => {
        await mutateAsync(id, {
          onSuccess: () => {
            toast.success(t('mainComponentDiscarded', { ns: 'main-component' }))
            invalidateMainComponentCache.getMainComponentsByComponentType(customerId, componentTypeId)
            invalidateMainComponentCache.getDiscardedMainComponents(customerId)
            invalidateMainComponentTypeCache.useGetMainComponentTypeWithMainComponentCount(customerId)
            pop()
          },
        })
      },
    )
  }

  const onSend = async (id: EntityId) => {
    const updated = await showDialog(MainComponentSendModalComponent, {
      title: translate('sendToServiceStation', { ns: 'main-component' }),
      componentProps: { customerId, localityId, mooringId, positionId, id },
      opts: {
        maxWidth: 'md',
        fullWidth: true,
      },
    })
    if (!updated) return
  }

  const [mainComponentId, setMainComponentId] = useState<EntityId | undefined>()
  const { mutateAsync: updateMainComponentToOutgoing, error } = useUpdateMainComponentToOutgoing(
    customerId,
    mainComponentId as EntityId,
  )

  const [selectedPositionId, setSelectedPositionId] = useState<EntityId>(0)
  const { mutateAsync: addMainComponentToLocality } = useAddMainComponentToLocality(customerId, selectedPositionId)

  const onSubmit = useCallback(
    async (form, pop) => {
      if (form.selectedPositionId != null) {
        setSelectedPositionId(form.selectedPositionId)
        await addMainComponentToLocality(form).then(
          () => {
            invalidateMainComponentCache.getMainComponents(customerId, localityId, mooringId, selectedPositionId)
            invalidateMainComponentCache.getOutgoingMainComponents(customerId, localityId)
            invalidateCertificateCache.getCertificatesByPosition(customerId, selectedPositionId)
            invalidatePositionCache.getCagePositionDeviations(customerId, localityId, selectedPositionId)
            invalidateMainComponentCache.getMainComponentsByComponentType(
              customerId,
              form.mainComponentTypeId as EntityId,
            )
            invalidateMainComponentCache.getMainComponentById(customerId, form.mainComponentId ?? 0)
            pop()
          },
          error => {
            if (error?.data == 'ExceedMaxCountForCage') {
              toast(t('exceedMaxCountForCage', { ns: 'main-component' }), { type: 'error' })
            }
            if (error && error['data'] == NO_VALID_CERTIFICATE) {
              toast(t('NoValidCertificate', { ns: 'service-station' }), { type: 'error' })
            }
          },
        )
      } else {
        await updateMainComponentToOutgoing(form).then(
          () => {
            invalidateMainComponentCache.getMainComponentsByComponentType(
              customerId,
              form.mainComponentTypeId as EntityId,
            )
            pop()
          },
          error => {
            if (error && error['data'] == NO_VALID_CERTIFICATE) {
              pop()
              toast(t('NoValidCertificate', { ns: 'service-station' }), { type: 'error' })
            }
          },
        )
      }
    },
    [customerId, localityId, mooringId, selectedPositionId, mainComponentId],
  )

  const onSendToOutgoing = useCallback(
    async (mainComponent: IMainComponent) => {
      setMainComponentId(mainComponent.id)
      const updated = await showDialog(SendToLocalityDialog, {
        componentProps: { customerId, mainComponentId: mainComponent.id as EntityId, onSubmit },
        opts: {
          maxWidth: 'sm',
          fullWidth: true,
        },
      }, undefined, true)
      if (!updated) return
    },
    [customerId, mainComponentId],
  )

  const renderMainComponentLocation = useCallback(params => {
    const mainComponent = params.row as IMainComponent

    if (mainComponent) {
      if (mainComponent.selectedPositionName) {
        return (
          <Box>
            {mainComponent.selectedLocalityName && <h5>{mainComponent.selectedLocalityName + '-'} </h5>}
            <h6>{getCageName(mainComponent.selectedPositionName)}</h6>
          </Box>
        )
      } else if (mainComponent.selectedLocalityName) {
        return (
          <Box>
            <h5>{mainComponent.selectedLocalityName ?? '-'} </h5>
          </Box>
        )
      } else {
        return (
          <Box>
            <h5>{mainComponent.assignedServiceStationName ?? '-'} </h5>
          </Box>
        )
      }
    }

    return <h5>{'-'}</h5>
  }, [])

  const onUpdateMainComponentType = useCallback(
    async id => {
      const added = await showDialog(UpdateMainComponentTypeDialog, {
        opts: {
          maxWidth: 'md',
          fullWidth: true,
        },
        componentProps: { id: id as EntityId, customerId: customerId as EntityId },
      }, undefined, true)
      if (!added) return
    },
    [customerId],
  )

  const onDeleteMainComponentType = useCallback(
    (id: EntityId) => {
      showConfirmDialog(
        translate('deleteMainComponentType', { ns: 'main-component-type' }),
        translate('areYouSureDeleteMainComponentType', { ns: 'main-component-type' }),
        undefined,
        async pop => {
          await deleteMainComponentType(id, {
            onSuccess: () => {
              toast(translate('type-deleted', { ns: 'main-component-type' }), { type: 'success' })
              invalidateMainComponentTypeCache.useGetMainComponentTypes(customerId as EntityId)
              invalidateMainComponentTypeCache.useGetMainComponentTypeWithMainComponentCount(customerId)
              pop()
            },
            onError: () => {
              toast.error(translate('deletingError', { ns: 'common' }))
            },
          })
        },
      )
    },
    [customerId],
  )

  const STATIC_COLUMNS: GridColumns = [
    {
      field: 'name',
      headerName: translate('name', { ns: 'common' }),
      flex: 1,
      cellClassName: 'font-weight-semi-bold',
    },
    {
      field: 'description',
      headerName: translate('description', { ns: 'common' }),
      flex: 2,
      renderCell: ({ row }) => (
        <DynamicTextTypography text={row.description} variant="body2" maxLength={40} fontWeight={500} />
      ),
    },
    { field: 'serialNumber', headerName: translate('serialNumber', { ns: 'main-component' }), flex: 1 },
    {
      field: 'supplier',
      headerName: translate('Supplier', { ns: 'main-component' }),
      flex: 1,
      valueGetter: ({ row }) => {
        return row?.supplier?.name ?? '-'
      },
    },
    { field: 'orderNumber', headerName: translate('orderNumber', { ns: 'main-component' }), flex: 1 },
    {
      field: 'dateProduced',
      headerName: translate('dateProduced', { ns: 'main-component' }),
      flex: 1,
      renderCell: params =>
        !params.value && typeof params.value !== 'string' ? (
          '-'
        ) : (
          <h5>{new Date(params.value).toLocaleDateString()}</h5>
        ),
    },
    {
      field: 'status',
      headerName: translate('status', { ns: 'work-assignment-position' }),
      flex: 1,
      renderCell: params => <h5>{translate(MainComponentStatus[params.value], { ns: 'main-component' })}</h5>,
    },
    {
      field: 'where',
      headerName: translate('where', { ns: 'main-component' }),
      flex: 1.2,
      renderCell: renderMainComponentLocation,
    },
  ]

  const columns: GridColumns = useMemo(
    () => [
      ...STATIC_COLUMNS,
      {
        field: 'actions',
        type: 'actions' as GridNativeColTypes,
        width:60,
        getActions: ({ id, row }) => [
          <GridActionsCellItem
            key={id}
            label={t('edit', { ns: 'common' })}
            icon={<MdEdit size={24} />}
            onClick={() => onUpdate(id)}
            color="primary"
            showInMenu
          />,
          <GridActionsCellItem
            key={id}
            label={t('clone', { ns: 'main-component' })}
            icon={<MdCopyAll size={24} />}
            onClick={() => onClone(row)}
            color="primary"
            showInMenu
          />,
          <GridActionsCellItem
            key={id}
            label={t('discard', { ns: 'common' })}
            icon={<MdDelete size={24} />}
            onClick={() => onDeleteMainComponent(id)}
            disabled={isDeleting || !hasPermission([permissionValue.ClientAdminPermission], permission)}
            color="primary"
            showInMenu
          />,
          <GridActionsCellItem
            key={id}
            label={translate('sendToServiceStation', { ns: 'common' })}
            icon={<MdSend size={24} />}
            onClick={() => onSend(id)}
            disabled={row.status != MainComponentStatus.NewlyRegistered}
            color="primary"
            showInMenu
          />,
          <GridActionsCellItem
            key={id}
            label={translate('sendToOutgoing', { ns: 'main-component' })}
            icon={<MdSend size={24} />}
            onClick={() => {
              onSendToOutgoing(row as IMainComponent)
            }}
            disabled={row.status != MainComponentStatus.NewlyRegistered}
            color="primary"
            showInMenu
          />,
        ],
      },
    ],
    [],
  )

  return (
    <>
      <GenericTable
        id={'main-components' + componentTypeId}
        columns={columns}
        defaultColumnOption={columns.map(c => c.headerName as string)}
        enableToolbar={true}
        toolbarOptions={[
          {
            label: translate('updateMainComponentType', { ns: 'main-component-type' }),
            Icon: <EditOutlined />,
            onClick: () => {
              onUpdateMainComponentType(componentTypeId)
            },
          },
          {
            label: translate('deleteMainComponentType', { ns: 'main-component-type' }),
            Icon: <DeleteOutline />,
            onClick: () => {
              onDeleteMainComponentType(componentTypeId)
            },
          },
          {
            label: translate('addMainComponent'),
            Icon: <AddOutlined />,
            onClick: () => {
              onClickMethod()
            },
          },
        ]}
        enableColumnOptions={true}
        queryUrl={mainEndpoints.getMainComponentsByComponentType(customerId as EntityId, componentTypeId)}
        enableServerPagination={true}
        rowColor={'#e1e1e1'}
        enableExport={itemCount > 0}
        isDownloading={exportMutation.isLoading}
        onDownload={async () => {
          exportMutation
            .mutateAsync({ customerId: customerId as EntityId, typeId: componentTypeId as number })
            .then(res => {
              downloadFileLocal(res, `${JSON.parse(mainComponentTypeName ?? '{en:download}').en}`)
            })
        }}
        onRowClick={cell => navigateTo(AppRoutesNavigation.Customer.mainComponent(customerId, cell?.id))}
        autoHeight={false}
        height={'calc(100vh - 380px)'}
      />
    </>
  )
}
