import { Grid, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useParams } from 'react-router-dom'
import { IMooring, IMooringType, IPosition } from '../../../generated-types'
import { useGetMainComponents, useGetOutgoingMainComponents } from '../../../api/main-component'
import AdditionalEquipment from './AdditionalEquipment'
import CageActivityLog from './CageHistory'
import { useTranslation } from 'react-i18next'
import { AddCircleOutline, GpsFixedTwoTone, GpsNotFixed } from '@mui/icons-material'
import { MainComponentAddComponent } from '../positions/components/MainComponentAddModal'
import { APIResultCount } from '../../../constant'
import { permissionValue } from '../../../enums/permissionValue'
import { CommonPageLayout } from 'src/components/common/CommonPageLayout'
import { Card } from 'src/styles/Index'
import { Flex } from 'src/styles/flexComponent'
import { GridActionButton } from 'src/components/common/GridActionButton'
import { GpsToDms } from 'src/components/GpsToDms'
import { DetailsCard } from '../positions/PositionContent'
import { MapComponent } from '../components/map/MapComponent'
import { LatLngExpression } from 'leaflet'
import { useMemo } from 'react'
import { styled } from '@mui/system'
import { Padding } from 'src/styles/paddingComponent'
import { invalidatePositionCache, useGetCagesForDropDownByMooringId, useUploadModelImage } from 'src/api/positions'
import { PositionsNavigationListContainer } from '../positions/PositionContainer'
import { CageCustomLogAddDialog } from '../positions/components/CageCustomLogAddDialog'
import { getThemeColor } from 'src/utils'
import { MUIThemeColors } from 'src/mui-theme'
import { useDialogCtx } from 'src/hooks/context-hooks'
import { toast } from 'react-toastify'

export const mainComponentsSectionHeight = (window.innerHeight - 600) / 2 < 389 ? 389 : (window.innerHeight - 600) / 2

const CageContainer: React.FC<{ position: IPosition; mooringDetails: IMooring; loading: boolean }> = ({
  position,
  mooringDetails,
  loading,
}) => {
  const { showDialog } = useDialogCtx()
  const { localityId, customerId, mooringId, positionId } = useParams()
  const isMobileScreen = useMediaQuery('(max-width: 600px)')
  const { data } = useGetCagesForDropDownByMooringId(
    customerId as EntityId,
    localityId as EntityId,
    mooringId as EntityId,
  )
  const theme = useTheme()

  const { data: mainComponents, isLoading: isMainComponentLoading } = useGetMainComponents(
    customerId as EntityId,
    { localityId, mooringId, positionId },
    0,
    APIResultCount.Max,
  )

  const { data: outgoingMainComponents } = useGetOutgoingMainComponents(customerId as EntityId, localityId as EntityId)

  const { t } = useTranslation(['common', 'main-component', 'position'])

  const onAdd = async () => {
    if (outgoingMainComponents?.items?.length === 0) {
      toast(t('no-outgoing-components', { ns: 'main-component' }), { type: 'warning' })
      return
    }
    const added = await showDialog(
      MainComponentAddComponent,
      {
        componentProps: {
          customerId: customerId as EntityId,
          mooringId: mooringId as EntityId,
          positionId: positionId as EntityId,
          localityId: localityId as EntityId,
        },
        opts: {
          maxWidth: 'sm',
          fullWidth: true,
        },
      },
      undefined,
      true,
    )
    if (!added) return
  }

  const onCustomLogAdd = async () => {
    const added = await showDialog(
      CageCustomLogAddDialog,
      {
        componentProps: {
          customerId: customerId as EntityId,
          mooringId: mooringId as EntityId,
          positionId: positionId as EntityId,
          localityId: localityId as EntityId,
        },
        opts: {
          maxWidth: 'sm',
          fullWidth: true,
        },
      },
      undefined,
      true,
    )
    if (!added) return
  }

  return (
    <div style={{ background: '#F1F5F7' }}>
      <CommonPageLayout enableBackButton titleSection={{ title: t('cage-overview', { ns: 'position' }) }}>
        <Padding.pb10>
          <PositionsNavigationListContainer positions={data} filter={[]} selectedPosition={position} />
        </Padding.pb10>
        <Flex.Column rowGap={'16px'}>
          <Grid container>
            <Grid md={6} paddingRight={'8px'}>
              <Card sx={{ height: '160px', width: '100%' }} className="pt-6-core pl-6-core pr-6-core">
                <Grid container className="pt-5-core">
                  <DetailsCard
                    icon={<img src={'/icons/hs.svg'} color="primary" />}
                    title={t('hs', { ns: 'common' })}
                    value={<GpsToDms decimalGPS={position?.longitude} isLongitude={true}></GpsToDms>}
                  />
                  <DetailsCard
                    icon={<img src={'/icons/vc.svg'} color="primary" />}
                    title={t('vc', { ns: 'common' })}
                    value={<GpsToDms decimalGPS={position?.latitude} isLongitude={false}></GpsToDms>}
                  />
                  <DetailsCard
                    icon={<img src={'/icons/vc15.svg'} color="primary" />}
                    title={t('vc15', { ns: 'position' })}
                    value={position?.mbl ?? '-'}
                  />
                  <DetailsCard
                    icon={<img src={'/icons/depth.svg'} color="primary" />}
                    title={t('depth', { ns: 'position' })}
                    value={position?.depth ?? '-'}
                  />
                  <DetailsCard
                    icon={<GpsFixedTwoTone color="primary" sx={{ fontSize: '28px' }} />}
                    title={t('longitude', { ns: 'common' })}
                    value={<GpsToDms decimalGPS={position?.longitude} isLongitude={true}></GpsToDms>}
                  />
                  <DetailsCard
                    icon={<GpsNotFixed color="primary" sx={{ fontSize: '28px' }} />}
                    title={t('latitude', { ns: 'common' })}
                    value={<GpsToDms decimalGPS={position?.latitude} isLongitude={false}></GpsToDms>}
                  />
                  <DetailsCard
                    icon={<img src={'/icons/break.svg'} color="primary" />}
                    title={t('mbl', { ns: 'position' })}
                    value={position?.mbl ?? '-'}
                  />
                  <DetailsCard
                    icon={<img src={'/icons/load.svg'} color="primary" />}
                    title={t('accident-load', { ns: 'position' })}
                    value={position?.accidentLoad ?? '-'}
                  />
                </Grid>
              </Card>
            </Grid>
            <Grid md={6} paddingLeft={'8px'}>
              <Card sx={{ height: '160px' }}>
                <CageMap
                  positions={mooringDetails?.positions}
                  position={position}
                  type={mooringDetails?.type as IMooringType}
                />
              </Card>
            </Grid>
          </Grid>
          <Card className="p-12-core" sx={{ height: mainComponentsSectionHeight }}>
            <CommonPageLayout
              titleSection={{ subtitle: t('equipment', { ns: 'sidebar' }) }}
              topAction={[
                <GridActionButton
                  onClick={onAdd}
                  key="add"
                  variant="outlined"
                  buttonTextProps={{ variant: 'subtitle1', fontWeight: 600 }}
                  buttonText={isMobileScreen ? undefined : t('add-equipment', { ns: 'position' })}
                  shadowWeight={1}
                  permissionBinaryValues={[permissionValue.Locality.Mooring.Write]}
                  buttonIcon={<AddCircleOutline htmlColor={theme.palette.primaryMain[theme.palette.mode]} />}
                  height="36px"
                />,
              ]}
            >
              <Grid container columnSpacing={'16px'}>
                <Grid item md={7}>
                  <Padding.ph12 border={'1px solid #E8E8E8'}>
                    <AdditionalEquipment additionalEquipment={mainComponents?.items} />
                  </Padding.ph12>
                </Grid>
                <Grid item md={5}>
                  <CageObject fileURL={position?.modelImageURL} />
                </Grid>
              </Grid>
            </CommonPageLayout>
          </Card>
          <Card className="p-20-core">
            <Flex.Row className="pl-8-core pr-8-core pt-8-core" justifyContent={'space-between'}>
              <Typography color={getThemeColor(theme, MUIThemeColors.primaryMain)} variant="h6">
                {t('activity-log', { ns: 'common' })}
              </Typography>
              <GridActionButton
                onClick={onCustomLogAdd}
                key="add"
                variant="outlined"
                buttonTextProps={{ variant: 'subtitle1', fontWeight: 600 }}
                buttonText={isMobileScreen ? undefined : t('custom-log', { ns: 'activity-logs' })}
                shadowWeight={1}
                permissionBinaryValues={[permissionValue.Locality.Mooring.Write]}
                buttonIcon={<AddCircleOutline htmlColor={theme.palette.primaryMain[theme.palette.mode]} />}
                height="36px"
              />
            </Flex.Row>
            <CageActivityLog id={positionId} customerId={customerId} localityId={localityId} mooringId={mooringId} />
          </Card>
        </Flex.Column>
      </CommonPageLayout>
    </div>
  )
}

interface ICageMap {
  positions: IPosition[]
  position: IPosition
  type: IMooringType | undefined
}

const CageMap: React.FC<ICageMap> = ({ positions, type, position }) => {
  const centerLat = useMemo(
    () =>
      position?.connectedPositions && position?.connectedPositions.length > 0
        ? (position?.connectedPositions[0].latitude + position?.connectedPositions[1]?.latitude) / 2
        : position && position.latitude !== 0
        ? position.latitude
        : positions && positions.length > 0
        ? positions[0].latitude
        : 0,
    [positions, position],
  )

  const centerLng = useMemo(
    () =>
      position?.connectedPositions && position?.connectedPositions.length > 0
        ? (position?.connectedPositions[0].longitude + position?.connectedPositions[1]?.longitude) / 2
        : position && position.longitude !== 0
        ? position.longitude
        : positions && positions.length > 0
        ? positions[0].longitude
        : 0,
    [positions, position],
  )

  return (
    <MapComponent
      zoom={17}
      positions={positions}
      center={[centerLat, centerLng] as LatLngExpression}
      mooringType={type}
    />
  )
}

export const CageObject = ({ fileURL }) => {
  const { t } = useTranslation('cage-status')
  const { localityId, customerId, positionId, mooringId } = useParams()
  const mutation = useUploadModelImage(customerId as EntityId, localityId as EntityId, positionId as EntityId)

  const onSelectUpload = () => {
    document.getElementById('cage-3d-drawing')?.click()
  }
  const onUpload = uploadedFile => {
    const formData = new FormData()
    formData.append('file', uploadedFile[0])
    mutation.mutateAsync(formData, {
      onSuccess: data => {
        invalidatePositionCache.getPosition(
          customerId as EntityId,
          localityId as EntityId,
          mooringId as EntityId,
          positionId as EntityId,
        )
      },
    })
  }
  return (
    <CardCageObject
      className="hover-pointer"
      onClick={() => onSelectUpload()}
      sx={{
        backgroundImage: `url(${fileURL})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        backgroundColor: '#c3ebff',
        width: '100%',
      }}
    >
      <input
        type="file"
        hidden
        id="cage-3d-drawing"
        accept="image/png,image/jpeg"
        onChange={event => onUpload(event.target.files)}
      />
      {fileURL == null && <Typography align="center">{t('cage-image-uploader')}</Typography>}
    </CardCageObject>
  )
}

export default CageContainer

//styles
const CardCageObject = styled(Card)`
  height: ${mainComponentsSectionHeight - 119}px;
  background-color: #baeef9 !important;
  justify-content: center;
  align-items: center;
  align-content: center;
`
