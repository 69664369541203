import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { z } from 'zod'
import { invalidateDeviation, useUpdateDeviation } from '../../api/deviation'
import { invalidateMainComponentCache } from '../../api/main-component'
import { DeviationStepperForm } from '../../forms/DeviationStepperForm/DeviationStepperForm'
import { IDeviation } from '../../generated-types/deviation'
import { IDeviationEventSupervision } from '../../generated-types/deviation-event-supervision'
import { IDeviationMeasure } from '../../generated-types/deviation-measure'
import { IDeviationPriority } from '../../generated-types/deviation-priority'
import { IDeviationType } from '../../generated-types/deviation-type'
import { IFormType } from '../../generated-types/form-type'
import { EnumToSelectTypes } from '../../generated-types/select-type'
import { useZodForm } from '../../hooks/zod-form'
import { ShowDialogProps } from '../../show-dialog'
import { toast } from 'react-toastify'

interface UpdateDeviationDialogProps extends ShowDialogProps {
  customerId: EntityId
  localityId?: EntityId
  componentId?: EntityId
  deviation: IDeviation
}

const UpdateDeviationDialog: React.FC<UpdateDeviationDialogProps> = ({
  pop,
  customerId,
  localityId,
  componentId,
  deviation,
}) => {
  const { t } = useTranslation(['deviation', 'common'])
  const updateMutation = useUpdateDeviation(customerId, deviation.id)

  const onSubmit = async form => {
    await updateMutation.mutateAsync(form, {
      onSuccess: async () => {
        invalidateDeviation.invalidateAllDeviations(customerId,undefined,undefined, deviation?.id)
        invalidateMainComponentCache.getMainComponentById(customerId, componentId as EntityId)
        toast.success(t('updated-deviation-successfully-toast', { ns: 'deviation' }))
        pop()
      }
    })
  }

  const updateDeviationSchema = z.object({
    keywords: z
      .any({
        errorMap: () => {
          return { message: t('selectOneOfTheGivenOptions', { ns: 'common' }) }
        },
      })
      .optional(),
    visualDeviation: z.boolean().optional(),
    measuredDeviation: z.boolean().optional(),
    normalWear: z.boolean().optional(),
    abnormalWear: z.boolean().optional(),
    measure: z
      .object({
        id: z.number(),
      })
      .transform(value => value.id),
    eventSupervision: z
      .object({
        id: z.number(),
      })
      .transform(value => value.id),
    reason: z.string(),
    measures: z.string(),
  })

  const deviationPriorities = EnumToSelectTypes(IDeviationPriority, 'deviation')
  const deviationTypes = EnumToSelectTypes(IDeviationType, 'deviation')
  const deviationMeasures = EnumToSelectTypes(IDeviationMeasure, 'deviation')
  const deviationEventSupervision = EnumToSelectTypes(IDeviationEventSupervision, 'deviation')

  const { priority, dueDate, type, measure, eventSupervision, keywords, ...rest } = deviation

  const form = useZodForm(updateDeviationSchema, {
    defaultValues: {
      priority: deviationPriorities[priority],
      type: deviationTypes[type],
      measure: deviationMeasures[measure],
      eventSupervision: deviationEventSupervision[eventSupervision],
      dueDate: moment(dueDate).format('YYYY-MM-DD'),
      keywords: deviation.keywordsArray,
      ...rest,
    },
  })

  return (
    <DeviationStepperForm
      onSubmit={onSubmit}
      onCancel={() => pop()}
      customerId={customerId}
      localityId={localityId}
      isDisableWizard={true}
      type={deviation.type}
      formType={IFormType.Update}
      form={form}
      title={`${t('updateDeviation')} - ${deviation.title}`}
      isLoading={updateMutation.isLoading}
    />
  )
}

export default UpdateDeviationDialog
