import { Box, Grid } from '@mui/material'
import { useEffect, useState } from 'react'
import { CenteredSpinner } from '../../components/Spinner'
import { useLocation } from 'react-router-dom'
import AllServiceStations from './container/AllServiceStations'
import { ServiceStationMapComponent } from './ServiceStationMapComponent'
import { useGetCustomersServiceStationsAdministrators } from '../../api/customer'

const ServiceStationMainPage = () => {
  const [open, setOpen] = useState(0)
  const [searchValue, setSearchValue] = useState('')
  const { data, isLoading, isSuccess } = useGetCustomersServiceStationsAdministrators(searchValue)
  const { hash } = useLocation()

  useEffect(() => {
    if (data && data.length > 0) {
      setOpen(data[0].id)
    }
  }, [data])

  useEffect(() => {
    setSearchValue(hash.replace('#', ''))
  }, [hash])

  return (
    <>
      {isLoading && <CenteredSpinner />}
      {isSuccess && (
        <Box className="relative z-10 h-full overflow-hidden bg-white" sx={{ width: '100%' }}>
          <Box className="pt-1">
            <Grid container>
              <Grid item md={7} lg={7} sm={12}>
                <AllServiceStations customers={data} open={open} setOpen={setOpen} />
              </Grid>
              <ServiceStationMapComponent
                isLoading={isLoading}
                selectedCustomer={data?.find(c => c.id == open)}
              />
            </Grid>
          </Box>
        </Box>
      )}
    </>
  )
}
export default ServiceStationMainPage
