import { GridActionsCellItem, GridColumns, GridNativeColTypes } from '@mui/x-data-grid-pro'
import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { MdDelete, MdEdit } from 'react-icons/md'
import {
  invalidateCustomerDocumentCategoryCache,
  useDeleteCustomerDocumentCategory,
  useGetCustomerDocumentCategories,
  useGetCustomerDocumentCategory,
  useUpdateCustomerDocumentCategory,
} from 'src/api/customer-document-category'
import { GenericDialogLayout } from 'src/components/GenericDialogLayout'
import GenericTable from 'src/components/table/GenericTable'
import { useCustomerCtx, useDialogCtx } from 'src/hooks/context-hooks'
import { ShowDialogProps } from 'src/show-dialog'
import { DocumentCategoryForm } from './DocumentCategoryForm'
import { IDocumentCategory } from 'src/generated-types/document-category'
import { CenteredSpinner } from 'src/components/Spinner'
import { toast } from 'react-toastify'

interface UpdateCustomerDocumentCategoryProps extends ShowDialogProps {
  id: EntityId
  customerId: EntityId
}

const UpdateCustomerDocumentCategoryDialog: React.FC<UpdateCustomerDocumentCategoryProps> = ({
  pop,
  id,
  customerId,
}) => {
  const { t: translate } = useTranslation(['documentcategory', 'common'])

  const mutation = useUpdateCustomerDocumentCategory(customerId, id as EntityId)

  useEffect(() => {
    if (mutation.error != null) {
      toast(translate('CannotUpdateDocumentCategory', { ns: 'document-category' }), { type: 'warning' })
    }
  }, [mutation.error, translate])

  const onSubmit = async form => {
    await mutation.mutateAsync(form)
    invalidateCustomerDocumentCategoryCache.getCustomerDocumentCategories(customerId)
    invalidateCustomerDocumentCategoryCache.getCustomerDocumentCategory(customerId, id)
    pop(true)
  }

  const { data, isLoading } = useGetCustomerDocumentCategory(customerId, id)
  if (isLoading) return <CenteredSpinner />

  return (
    <GenericDialogLayout title={translate('updateDocumentCategory', { ns: 'document-category' })} pop={pop}>
      <DocumentCategoryForm
        onSubmit={onSubmit}
        onCancel={() => pop()}
        initialValues={data as IDocumentCategory}
        title={''}
      />
    </GenericDialogLayout>
  )
}

export const DocumentCategoryTable = () => {
  const { t: translate } = useTranslation(['document-category', 'common'])
  const { customer } = useCustomerCtx()
  const { showDialog, showConfirmDialog } = useDialogCtx()
  const { data: documentCategories } = useGetCustomerDocumentCategories(customer?.id)
  const { mutate } = useDeleteCustomerDocumentCategory(customer?.id)

  const onUpdate = async id => {
    const added = await showDialog(
      UpdateCustomerDocumentCategoryDialog,
      {
        componentProps: { id: id as EntityId, customerId: customer?.id },
        opts: {
          maxWidth: 'sm',
          fullWidth: true,
        },
      },
      undefined,
      true,
    )
    if (!added) return
  }

  const onDeleteDocumentCategory = (id: EntityId) => {
    showConfirmDialog(
      translate('deleteDocumentCategory', { ns: 'document-category' }),
      translate('areYouSureDeleteDocumentCategory', { ns: 'document-category' }),
      { acceptText: translate('yes', { ns: 'common' }), cancelText: translate('no', { ns: 'common' }) },
      async pop => {
        await mutate(id, {
          onSuccess: () => {
            invalidateCustomerDocumentCategoryCache.getCustomerDocumentCategories(customer?.id)
            pop()
          },
        })
      },
    )
  }

  const STATIC_COLUMNS: GridColumns = [
    {
      field: 'categoryName',
      headerName: translate('categoryName', { ns: 'document-category' }),
      flex: 1,
      cellClassName: 'font-weight-semi-bold',
    },
  ]

  const columns: GridColumns = useMemo(
    () => [
      ...STATIC_COLUMNS,
      {
        field: 'actions',
        type: 'actions' as GridNativeColTypes,
        width: 10,
        getActions: ({ id }) => [
          <GridActionsCellItem
            key={id}
            label={translate('edit', { ns: 'common' })}
            icon={<MdEdit size={24} />}
            onClick={() => onUpdate(id)}
            color="primary"
            showInMenu
          />,
          <GridActionsCellItem
            key={id}
            label={translate('delete', { ns: 'common' })}
            icon={<MdDelete size={24} />}
            onClick={() => onDeleteDocumentCategory(id)}
            color="primary"
            showInMenu
          />,
        ],
      },
    ],
    [translate],
  )
  return (
    <GenericTable
      id={'customer-document-categories'}
      data={documentCategories?.items ?? []}
      columns={columns}
      defaultColumnOption={columns.map(c => c.headerName as string)}
      enablePagination={false}
      hideDefaultFooterPagination={true}
      getRowClassName={params => (params.indexRelativeToCurrentPage % 2 == 0 ? 'even' : 'odd')}
      autoHeight={false}
      height={700}
    />
  )
}
