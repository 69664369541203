import { generateQueryKey } from '@lovoldsystem/core.client'
import { formSchemaReportTypes } from './generated-types/form-schema'
import { IDocumentCategoryRequestDto } from './generated-types'

export const serviceStationGroupQueryKey = {
  GET_SERVICE_STATION_GROUPS: (customerId?: EntityId, queryParams?: any) =>
    generateQueryKey('service-station-group', { customerId, ...queryParams }),
  GET_SERVICE_STATION_GROUPS_FOR_AUTH: (customerId?: EntityId) =>
    generateQueryKey('service-station-group-for-auth', { customerId }),
  GET_SERVICE_STATIONS_FOR_GROUP: (customerId?: EntityId, id?: EntityId) =>
    generateQueryKey('service-station-group-service-stations', { customerId, id }),
}

export const accessGroupQueryKey = {
  GET_LOCALITY_USERS: (customerId: EntityId, id: EntityId) => generateQueryKey('locality-users', { customerId, id }),
  GET_SERVICE_STATION_GROUP_USERS: (customerId: EntityId, id: EntityId) =>
    generateQueryKey('service-station-group-users', { customerId, id }),
  GET_SERVICE_STATION_USERS: (customerId: EntityId, id: EntityId) =>
    generateQueryKey('service-station-users', { customerId, id }),
  GET_FACILITIES: (queryParams?: any) => generateQueryKey('facilities', { ...queryParams }),
}

export const userQueryKey = {
  GET_USER_INVITE: (code?: EntityId) => generateQueryKey('user-invite', { code }),
}

export const activityLogQueryKey = {
  GET_ACTIVITY_LOGS: (queryParams?: { positionId?: EntityId; page?: number; pageSize?: number }) => {
    return generateQueryKey('get-activity-logs', queryParams)
  },
}

export const localityQueryKeys = {
  GET_LOCALITIES: (customerId?: EntityId, queryParams?: any) =>
    generateQueryKey('localities', { customerId, ...queryParams }),
  GET_LOCALITIES_LOGO_NAME: (customerId?: EntityId) => generateQueryKey('localities-name-and-logo', { customerId }),
  GET_LOCALITY: (customerId?: EntityId, localityId?: EntityId, queryParams?: { mainComponentId?: EntityId }) =>
    generateQueryKey('locality', { customerId, localityId, ...queryParams }),
  GET_LOCALITY_WITH_ACTIVITY_COUNTS: (customerId?: EntityId, localityId?: EntityId) =>
    generateQueryKey('locality-with-activity-counts', { customerId, localityId }),
  GET_CAGE_STATUS: (customerId?: EntityId, localityId?: EntityId, queryParams?: object) => {
    const params = { customerId, localityId, ...queryParams }
    removeNullParams(params)
    return generateQueryKey('cage-status', { customerId, localityId, ...queryParams })
  },
  GET_LOCALITY_DEVIATION_COUNT: (customerId?: EntityId, localityId?: EntityId, mooringId?: EntityId) =>
    generateQueryKey('deviation-count', { customerId, localityId, mooringId }),
  GET_LOCALITY_WITH_INFO_COUNT: (customerId?: EntityId) => generateQueryKey('locality-with-info-count', { customerId }),
  GET_GROUPPED_LOCALITY_WITH_INFO_COUNT: (customerId?: EntityId) =>
    generateQueryKey('groupped-locality-with-info-count', { customerId }),
}

export const formSchemaQueryKeys = {
  GetFormSchemaReports: (queryParams?: {
    customerId?: EntityId
    type?: formSchemaReportTypes
    localityFrequencyIds?: Array<number | undefined>
    deviationId?: string
    mainComponentId?: string
    scheduledTaskId?: number
  }) => generateQueryKey('form-schema-reports', { ...queryParams }),
}

export const localityFrequencyQueryKeys = {
  GET_LOCALITY_FREQUENCIES: (customerId?: EntityId, localityId?: EntityId, queryParams?: object) =>
    generateQueryKey('localityFrequencies', { customerId, localityId, ...queryParams }),
  GET_LOCALITY_FREQUENCY: (
    customerId?: EntityId,
    localityId?: EntityId,
    queryParams?: { id?: number | EntityId; groupId?: string },
  ) => generateQueryKey('locality-frequency', { customerId, localityId, ...queryParams }),
}

export const DocumentCategoryKeys = {
  GET_DOCUMENT_CATEGORIES_FOR_DOCUMENTS: (customerId?: EntityId, queryParams?: IDocumentCategoryRequestDto) =>
    generateQueryKey('documentCategoriesForDocuments', { customerId, ...queryParams }),
}

export const serviceStationQueryKey = {
  GET_USERS_FOR_SERVICE_STATION: (customerId?: EntityId, id?: EntityId) =>
    generateQueryKey('service-station-users', { customerId, id }),
  GET_SERVICE_STATION_GROUPS: (customerId?: EntityId, serviceStationId?: EntityId, queryParams?: object) =>
    generateQueryKey('service-station-groups', { customerId, serviceStationId, ...queryParams }),
}

export const customerQueryKey = {
  GET_CUSTOMER_USERS: (customerId?: EntityId, isWithClientUserLinks?: boolean) =>
    generateQueryKey('customer-users', { customerId, isWithClientUserLinks: isWithClientUserLinks as any }),
  GET_CUSTOMER: (customerId?: EntityId) => generateQueryKey('customer', { customerId }),
  GET_LOCALITIES_FOR_MAP: (searchValue?: string, selectedCustomer?: EntityId) =>
    generateQueryKey('localities-for-map', { searchValue, selectedCustomer }),
}

export const userInviteQueryKey = {
  GET_USER_INVITES: (customerId?: EntityId, queryParams?: any) =>
    generateQueryKey('user-invites', { customerId, ...queryParams }),
}

export const positionQueryKey = {
  GET_Position_DEVIATION_COUNT: (customerId?: EntityId, localityId?: EntityId, positionId?: EntityId) => {
    const params = { customerId, localityId, positionId }
    removeNullParams(params)
    return generateQueryKey('position-deviation-count', params)
  },
  GET_POSITIONS: (customerId?: EntityId, localityId?: EntityId, mooringId?: EntityId, queryParams?: object) => {
    const params = { customerId, localityId, mooringId, ...queryParams }
    removeNullParams(params)
    return generateQueryKey('get-positions', params)
  },
  GET_POSITIONS_MINIMAL_DATA: (
    customerId?: EntityId,
    localityId?: EntityId,
    mooringId?: EntityId,
    queryParams?: object,
  ) => {
    const params = { customerId, localityId, mooringId, ...queryParams }
    removeNullParams(params)
    return generateQueryKey('get-positions-minimal-data', params)
  },
  GET_POSITION: (
    customerId?: EntityId,
    localityId?: EntityId,
    mooringId?: EntityId,
    positionId?: EntityId,
    queryParams?: object,
  ) => {
    const params = { customerId, localityId, mooringId, positionId, ...queryParams }
    removeNullParams(params)
    return generateQueryKey('get-position', params)
  },
  GET_CAGE_SELECT_OPTION_BY_MOORING: (customerId?: EntityId, localityId?: EntityId, mooringId?: EntityId) => {
    const params = { customerId, localityId, mooringId }
    removeNullParams(params)
    return generateQueryKey('get-cage-select-options-by-mooring', params)
  },
}

export const workAssignmentQueryKeys = {
  GET_WORK_ASSIGNMENTS: (customerId?: EntityId, localityId?: EntityId, queryParams?: any) =>
    generateQueryKey('work-assignments', { customerId, localityId, ...queryParams }),
  GET_WORK_ASSIGNMENT_POSITIONS: (
    customerId?: EntityId,
    localityId?: EntityId,
    queryParams?: { reportId?: number; mooringId?: EntityId },
  ) => generateQueryKey('work-assignment-mooring-position', { customerId, localityId, ...queryParams }),
  GET_WORK_ASSIGNMENT: (customerId?: EntityId, localityId?: EntityId, id?: EntityId) =>
    generateQueryKey('single-work-assignment', { customerId, localityId, id }),
}

export const documentQueryKeys = {
  GET_DOCUMENT_EXPIRATION_TYPES: (customerId?: EntityId, localityId?: EntityId) =>
    generateQueryKey('documentExpirationTypes', { customerId, localityId }),
}

export const mooringQueryKeys = {
  GET_MOORING: (customerId?: EntityId, localityId?: EntityId, id?: EntityId, queryParams?: any) =>
    generateQueryKey('get-mooring', { customerId, localityId, id, ...queryParams }),
  GET_MOORINGS_FOR_MAP: (customerId?: EntityId, localityId?: EntityId, queryParams?: any) =>
    generateQueryKey('mooringsForMap', { customerId, localityId, ...queryParams }),
  GET_SVG_FOR_POSITION: (customerId?: EntityId, localityId?: EntityId, mooringId?: EntityId, queryParams?: any) =>
    generateQueryKey('svg-for-position', { customerId, localityId, mooringId, ...queryParams }),
  GET_MAP_SVG: (customerId?: EntityId, localityId?: EntityId, mooringId?: EntityId) =>
    generateQueryKey('svg-map', { customerId, localityId, mooringId }),
  GET_MOORING_FOR_DROPDOWN: (customerId?: EntityId, localityId?: EntityId) =>
    generateQueryKey('mooring-for-dropdown', { customerId, localityId }),
  GET_INACTIVE_MOORINGS: (customerId?: EntityId, localityId?: EntityId) =>
    generateQueryKey('inactive-moorings', { customerId, localityId }),
}

export const mainComponentQueryKeys = {
  GET_MAIN_COMPONENTS: (customerId?: EntityId, queryParams?: any) => {
    const params = { customerId, ...queryParams }
    return generateQueryKey('mainComponents', { customerId, ...params })
  },
  GET_EQUIPMENT: (customerId?: EntityId, queryParams?: any) =>
    generateQueryKey('equipment', { customerId, ...queryParams }),
  GET_EQUIPMENT_TYPES: (customerId?: EntityId, queryParams?: any) =>
    generateQueryKey('equipment-types', { customerId, ...queryParams }),
}

export const deviationQueryKeys = {
  GET_WEEKLY_COUNT: (customerId?: EntityId, localityId?: EntityId) =>
    generateQueryKey('deviation-weeklyCount', { customerId, localityId }),
  GET_ADMIN_DEVIATIONS: (customerId?: EntityId) => generateQueryKey('admin-deviations', { customerId }),
  GET_ADMIN_DEVIATION: (id?: EntityId) => generateQueryKey('admin-deviation', { id }),
  GET_DEVIATION: (customerId?: EntityId, id?: EntityId) => generateQueryKey('deviation', { customerId, id }),
  GET_DEVIATIONS: (customerId?: EntityId, queryParams?: object) =>
    generateQueryKey('deviations', { customerId, ...queryParams }),
  GET_DEVIATION_DOCUMENTS: (customerId?: EntityId, id?: EntityId) =>
    generateQueryKey('deviation-documents', { customerId, id }),
}

export const taskQueryKeys = {
  GET_SCHEDULED_TASKS: (localityId?: EntityId, queryParams?: object) => {
    const params = { localityId, ...queryParams }
    removeNullParams(params)
    return generateQueryKey('get-all-scheduled-tasks', params)
  },
  GET_SCHEDULED_TASK: (localityId?: EntityId, taskId?: EntityId, queryParams?: object) =>
    generateQueryKey('get-scheduled_Task', { localityId, taskId, ...queryParams }),
}

const removeNullParams = (obj: Record<string, any>): Record<string, any> => {
  Object.keys(obj).forEach(key => {
    if (obj[key] === null) {
      delete obj[key]
    }
  })
  return obj
}
